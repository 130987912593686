import { ChevronDownIcon } from '@chakra-ui/icons';
import { Flex, Image, Text } from '@chakra-ui/react';
import { useState } from 'react';
import 'react-multi-email/dist/style.css';
import { IResponse } from '../../../_services/interface/user.interface';
import { checkCollaboratorPermission } from '../Users/utils/checkPermission';

interface IConfigPanelProps {
  setSelectedContent: React.Dispatch<React.SetStateAction<string>>;
  selectedContent: string;
  validation: IResponse;
  guid_client: string;
}

export const ConfigPanelSidebar = (props: IConfigPanelProps) => {
  const [isExpandedProducts,  setIsExpandedProducts] = useState<boolean>(false);

  return (<>
    <Flex
      backgroundColor="white"
      height="100%"
      width="20%"
      borderRadius="0 6px 6px 0"
      padding="24px 16px"
      fontFamily="Poppins-Medium"
      marginLeft="-23px"
      direction={'column'}
    >
      {/* Sidebar */}
      <Flex marginBottom="25px">
        <Text
          fontSize="12px"
          color="#718096"
        >
          Configurações de usuário
        </Text>
      </Flex>

      <Flex
        marginBottom="16px"
        alignItems={'center'}
        padding="8px"
        cursor={'pointer'}
        _hover={{
          backgroundColor: '#F7FAFC',
          borderRadius: '8px'
        }}
        backgroundColor={props.selectedContent === 'account' ? '#ECF1FF' : 'none'}
        onClick={() => props.setSelectedContent('account')}
      >
        <Image
          cursor="pointer"
          height="24px"
          width="24px"
          src="/icons/config/persona.svg"
          resize="none"
          marginLeft="2px"
        />
        <Text 
          fontSize="14px"
          fontFamily="Poppins-Medium"
          fontWeight="400"
          marginLeft="8px"
          color={props.selectedContent === 'account' ? '#4B4EFF' : '#171923'}
        >
          Minha Conta
        </Text>
      </Flex>

      <Flex
        marginBottom="16px"
        alignItems={'center'}
        padding="8px"
        cursor={'pointer'}
        _hover={{
          backgroundColor: '#F7FAFC',
          borderRadius: '8px'
        }}
        backgroundColor={props.selectedContent === 'display' ? '#ECF1FF' : 'none'}
        onClick={() => props.setSelectedContent('display')}
      >
        <Image
          cursor="pointer"
          height="24px"
          width="24px"
          src="/icons/config/eye.svg"
          resize="none"
          marginLeft="2px"
        />
        <Text 
          fontSize="14px"
          fontFamily="Poppins-Medium"
          fontWeight="400"
          marginLeft="8px"
          color={props.selectedContent === 'display' ? '#4B4EFF' : '#171923'}
        >
          Configurações de exibição
        </Text>
      </Flex>

      {checkCollaboratorPermission(props.validation, props.guid_client, "config.read") && (
        <>
          <Flex
            marginBottom="16px"
            alignItems="center"
            padding="8px"
            cursor="pointer"
            _hover={{
              backgroundColor: "#F7FAFC",
              borderRadius: "8px",
            }}
            backgroundColor={props.selectedContent === "notification" ? "#ECF1FF" : "none"}
            onClick={() => props.setSelectedContent("notification")}
          >
            <Image
              cursor="pointer"
              height="24px"
              width="24px"
              src="/icons/config/notification.svg"
              resize="none"
              marginLeft="2px"
            />
            <Text
              fontSize="14px"
              fontFamily="Poppins-Medium"
              fontWeight="400"
              marginLeft="8px"
              color={props.selectedContent === "notification" ? "#4B4EFF" : "#171923"}
            >
              Notificações
            </Text>
          </Flex>

          <Flex marginBottom="25px">
            <Text fontSize="12px" color="#718096">
              Configurações de produtos
            </Text>
          </Flex>

          <Flex
            marginBottom="16px"
            alignItems="center"
            padding="8px"
            cursor="pointer"
            _hover={{
              backgroundColor: "#F7FAFC",
              borderRadius: "8px",
            }}
            backgroundColor={props.selectedContent === "company" ? "#ECF1FF" : "none"}
            onClick={() => props.setSelectedContent("company")}
          >
            <Image
              cursor="pointer"
              height="24px"
              width="24px"
              src="/icons/config/companies.svg"
              resize="none"
              marginLeft="2px"
            />
            <Text
              fontSize="14px"
              fontFamily="Poppins-Medium"
              fontWeight="400"
              marginLeft="8px"
              color={props.selectedContent === "company" ? "#4B4EFF" : "#171923"}
            >
              Empresas
            </Text>
          </Flex>

          <Flex
            marginBottom="16px"
            alignItems="center"
            padding="8px"
            cursor="pointer"
            _hover={{
              backgroundColor: "#F7FAFC",
              borderRadius: "8px",
            }}
            justifyContent="space-between"
            onClick={() => setIsExpandedProducts(!isExpandedProducts)}
          >
            <Flex>
              <Image
                cursor="pointer"
                height="24px"
                width="24px"
                src="/icons/config/products.svg"
                resize="none"
                marginLeft="2px"
              />
              <Text
                fontSize="14px"
                fontFamily="Poppins-Medium"
                fontWeight="400"
                color="#171923"
                marginLeft="8px"
              >
                Produtos
              </Text>
            </Flex>

            <ChevronDownIcon
              className={isExpandedProducts ? "rotate" : "rotate-reverse"}
              w={4}
              h={4}
              color="#171923"
            />
          </Flex>

          {isExpandedProducts && (
            <>
              <Flex
                width="90%"
                marginLeft="10%"
                marginBottom="16px"
                alignItems="center"
                padding="8px"
                cursor="pointer"
                _hover={{
                  backgroundColor: "#F7FAFC",
                  borderRadius: "8px",
                }}
                backgroundColor={props.selectedContent === "certificate" ? "#ECF1FF" : "none"}
                onClick={() => props.setSelectedContent("certificate")}
              >
                <Text
                  fontSize="14px"
                  fontFamily="Poppins-Medium"
                  fontWeight="400"
                  marginLeft="8px"
                  color={props.selectedContent === "certificate" ? "#4B4EFF" : "#171923"}
                >
                  Gerenciador de Certidões
                </Text>
              </Flex>

              <Flex
                width="90%"
                marginLeft="10%"
                marginBottom="16px"
                alignItems="center"
                padding="8px"
                cursor="pointer"
                _hover={{
                  backgroundColor: "#F7FAFC",
                  borderRadius: "8px",
                }}
                backgroundColor={props.selectedContent === "taxCurrentAccount" ? "#ECF1FF" : "none"}
                onClick={() => props.setSelectedContent("taxCurrentAccount")}
              >
                <Text
                  fontSize="14px"
                  fontFamily="Poppins-Medium"
                  fontWeight="400"
                  marginLeft="8px"
                  color={props.selectedContent === "taxCurrentAccount" ? "#4B4EFF" : "#171923"}
                >
                  Conta Corrente Fiscal
                </Text>
              </Flex>

              <Flex
                width="90%"
                marginLeft="10%"
                marginBottom="16px"
                alignItems="center"
                padding="8px"
                cursor="pointer"
                _hover={{
                  backgroundColor: "#F7FAFC",
                  borderRadius: "8px",
                }}
                backgroundColor={props.selectedContent === "taxKanban" ? "#ECF1FF" : "none"}
                onClick={() => props.setSelectedContent("taxKanban")}
              >
                <Text
                  fontSize="14px"
                  fontFamily="Poppins-Medium"
                  fontWeight="400"
                  marginLeft="8px"
                  color={props.selectedContent === "taxKanban" ? "#4B4EFF" : "#171923"}
                >
                  Tax Kanban
                </Text>
              </Flex>
            </>
          )}
        </>
      )}
    </Flex>
  </>)
}
