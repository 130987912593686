import { IDigitalCertificateCompany } from "../../../../_services/interface/digitalCertificate.interface";

export const companyHolder = (company: IDigitalCertificateCompany) => {
  if(company.holder) {
    return {
      text: 'Titular',
      color: '#DCFCE7',
      textColor: '#000000',
    }
  }

  return {
    text: '',
    color: '#000000',
    textColor: '#FFFFFF',
  }
}