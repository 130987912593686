import { Flex } from "@chakra-ui/react"
import { ICardList } from "../../interfaces/ICardList";
import { useEffect, useState } from "react";
import moment from "moment";

import { getClientEmployees } from "../../../../../_services/client.service";
import { IFilterListCompanyGroup } from "../../../../../_services/interface/companyGroup.interface";
import { listCompanyGroup } from "../../../../../_services/companyGroup.service";
import { FilterDataSelected, FilterRow, FilterTypeEnum, Filters } from "../../../../../components/Filters";
import { IGetCards } from "../../../../../_services/interface/kanban.interface";

interface IFiltersCards {
  guid_client: string;
  kanbanData: ICardList[];
  setFilteredCards: any;
  reinitFilter: boolean;
  setFilterData: React.Dispatch<React.SetStateAction<IGetCards | undefined>>
}

export const FiltersCards = (props: IFiltersCards) => {
  const [kanbanData, setKanbanData] = useState<ICardList[]>([]);
  const [cardNameLength, setCardNameLength] = useState<number>(0);

  const [filterDataSelected, setFilterDataSelected] = useState<FilterDataSelected|null>(null);

  useEffect(() => {
    setKanbanData(props.kanbanData);
  }, [props.kanbanData]);

  useEffect(() => {
      if(kanbanData) {
          let _clone: ICardList[] = [...kanbanData];

          if (filterDataSelected) {
            if (filterDataSelected[FilterTypeEnum.monthFilter].length > 0) {
                _clone = _clone.filter((_c) => filterDataSelected[FilterTypeEnum.monthFilter].includes(moment(_c.dueDate).format('MM')));
            }

            if (filterDataSelected[FilterTypeEnum.yearFilter].length > 0) {
                _clone = _clone.filter((_c) => filterDataSelected[FilterTypeEnum.yearFilter].includes(moment(_c.dueDate).format('YYYY')));
            }

            if (filterDataSelected[FilterTypeEnum.collaboratorFilter].length > 0) {
              _clone = _clone.filter((_c) => _c.responsibles.some((r) => filterDataSelected[FilterTypeEnum.collaboratorFilter].includes(r.guid_collaborator)));
            }

            if (filterDataSelected[FilterTypeEnum.companyCodeFilter].length > 0) {
                _clone = _clone.filter((_c) => _c.companies.some((r) => filterDataSelected[FilterTypeEnum.companyCodeFilter].includes(r.companyCode)));
              }

            if (filterDataSelected[FilterTypeEnum.companyFilter].length > 0) {
                _clone = _clone.filter((_c) => _c.companies.some((r) => filterDataSelected[FilterTypeEnum.companyFilter].includes(r.guid_company)));
            }

            if (filterDataSelected[FilterTypeEnum.companyGroupFilter].length > 0) {
                _clone = _clone.filter((_c) => _c.groups.some((r) => filterDataSelected[FilterTypeEnum.companyGroupFilter].includes(r.guid_company_group)));
            }

            if (filterDataSelected[FilterTypeEnum.jurisdictionFilter].length > 0) {
                _clone = _clone.filter((_c) => filterDataSelected[FilterTypeEnum.jurisdictionFilter].includes(_c.jurisdiction));
            }

            if (filterDataSelected[FilterTypeEnum.kanbanScheduleFilter].length > 0) {
                _clone = _clone.filter((_c) => filterDataSelected[FilterTypeEnum.kanbanScheduleFilter].includes(_c.schedule.guid_schedule));
            }

            if (filterDataSelected[FilterTypeEnum.kanbanPeriodFilter].length > 0) {
                _clone = _clone.filter((_c) => _c.calculationPeriod !== 'empty' &&_c.calculationPeriod && filterDataSelected[FilterTypeEnum.kanbanPeriodFilter].includes(_c.calculationPeriod));
            }

            if (filterDataSelected && cardNameLength !== filterDataSelected[FilterTypeEnum.kanbanCardNameFilter].length) {
              setCardNameLength(filterDataSelected[FilterTypeEnum.kanbanCardNameFilter].length);
            }
          }
          props.setFilteredCards(_clone); 
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kanbanData, filterDataSelected]);

  const getFilter = (filterData?: FilterDataSelected): IGetCards => {
    const cardNames: string[] = filterData ? filterData[FilterTypeEnum.kanbanCardNameFilter] : [];

    return {
      cardName: cardNames.length > 0 ? cardNames : undefined,
    }
  }

  useEffect(() => {
    props.setFilterData(getFilter(filterDataSelected ?? undefined));
  }, [cardNameLength]);

  const filterRows = [
    {
      rowFields: [
        {
          name: FilterTypeEnum.jurisdictionFilter,
          position: 1,
          size: 3,
        },
        {
          name: FilterTypeEnum.kanbanPeriodFilter,
          position: 2,
          size: 3,
        },
        {
          name: FilterTypeEnum.monthFilter,
          position: 3,
          size: 3,
        },

        {
          name: FilterTypeEnum.yearFilter,
          position: 4,
          size: 3,
        },
      ],
      position: 1
    },
    {
      rowFields: [
        {
          name: FilterTypeEnum.companyFilter,
          position: 1,
          size: 6,
        },
        {
          name: FilterTypeEnum.companyGroupFilter,
          position: 2,
          size: 3,
        },
        {
          name: FilterTypeEnum.companyCodeFilter,
          position: 3,
          size: 3,
        },
      ],
      position: 2
    },
    {
      rowFields: [
        {
          name: FilterTypeEnum.collaboratorFilter,
          position: 1,
          size: 4,
        },
        {
          name: FilterTypeEnum.kanbanScheduleFilter,
          position: 2,
          size: 4,
        },
        {
          name: FilterTypeEnum.kanbanCardNameFilter,
          position: 3,
          size: 4,
        },
      ],
      position: 3
    },
  ] as FilterRow[];

  return (
    <>
      <Flex display={"flex"} direction={"column"}>
        <Filters
          filters={filterRows}
          selectedValuesHook={setFilterDataSelected}
          reinitFilter={props.reinitFilter}
          allCompanies={true}
          persistDefaultFilter={true}
        />
      </Flex>
    </>
  );
}

