import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  Button,
  ButtonGroup,
  Text,
  useToast,
  Input,
  Link,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useRef, useState } from "react";
import { FormButton } from "../../../../components/login/button.component";
import { AddIcon } from "@chakra-ui/icons";
import { EJurisdiction } from "../../TaxKanban/cardRegistration.dto";
import { SelectCompanies } from "../../../../modal/SelectCompanies";
import { IClientCompanies } from "../../../../_services/interface/company.interface";
import { getClientTaxCurrentAccountIntegrations } from "../../../../_services/certificate.service";
import CustomAsyncSelect, { IOption } from "../../../../components/CustomAsyncSelect";
import { cityList, stateList } from "../../../../utils/region";
import { useClientContext } from "../../../../components/sidebar";
import { ICertificate } from "../../../../_services/interface/certificate.interface";
import { loadCompanies } from "../../../../utils/company";
import { addNewRegister } from "../../../../_services/taxCurrentAccount.service";
import { EToastStatus } from "../../../../components/ToastEnum";
import { set } from "date-fns";
import { TaxlyInput } from "../../../../components/Input";

interface ITaxCurrentAccountAddNewManualProps {
  flushHook: React.Dispatch<React.SetStateAction<boolean>>;
}

export const TaxCurrentAccountAddNewManual = (props: ITaxCurrentAccountAddNewManualProps) => {
  const { guid_client } = useClientContext();

  const SUPPORTED_FORMATS = ["application/pdf"];
  const fileUpload = useRef<HTMLInputElement>(null);

  const [states, setStates] = useState<IOption[]>([]);
  const [cities, setCities] = useState<IOption[]>([]);

  const [submitDisabled, setSubmitDisabled] = useState<boolean>(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [accErrorMessage, setAccErrorMessage] = useState<string[]>([]);
  const [simpleErrorMessage, setSimpleErrorMessage] = useState<string>();
  const [allCompanies, setAllCompanies] = useState<IClientCompanies[]>([]);
  const [openSelectCompanies, setOpenSelectCompanies] = useState<boolean>(false);
  const [isLoadingCompany, setLoadingCompany] = useState<boolean>(true);
  
  const [certificates, setCertificates] = useState<ICertificate[]>([]);
  const [certificateStatuses, setCertificateStatuses] = useState<IOption[]>([]);
  
  // Values
  const [companiesSelected, setCompaniesSelected] = useState<string[]>([]);
  const [slugSelected, setSlugSelected] = useState<string>('');
  const [jurisdictionSelected, setJurisdictionSelected] = useState<string>('');
  const [slugStatusSelected, setSlugStatusSelected] = useState<string>('');
  const [originName, setOriginName] = useState<string|null>(null);
  const [stateSelected, setStateSelected] = useState<number|null>(null);
  const [citySelected, setCitySelected] = useState<number|null>(null);
  const [fileUploadValue, setFileUploadValue] = useState<FileList>();
  

  const toast = useToast();

  const validateFile = (): boolean => {
    if (fileUploadValue) {
      if (fileUploadValue.item(0)) {
        if (SUPPORTED_FORMATS.includes(fileUploadValue.item(0)?.type as string)) {
          return true;
        } else {
          setSimpleErrorMessage('Apenas arquivos PDFs são aceitos');
          return false;
        }
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  const submit = async () => {
    if(!guid_client) {
      return;
    }

    setSubmitDisabled(true);
    setSimpleErrorMessage('');
    setAccErrorMessage([]);

    const error = [];
    
    let hasError = false;

    const validFile = validateFile();

    if(!jurisdictionSelected) {
      hasError = true;
      error.push('Selecione a jurisdição');
    }

    if(!slugSelected) {
      hasError = true;
      error.push('Selecione o tipo de relatório');
    }

    if(!slugStatusSelected) {
      hasError = true;
      error.push('Selecione a situação');
    }

    if(!originName) {
      hasError = true;
      error.push('Selecione a Origem/Órgão');
    }

    if(jurisdictionSelected === 'state' && !stateSelected) {
      hasError = true;
      error.push('Selecione o estado');
    }

    if(jurisdictionSelected === 'municipal' && (!citySelected || !stateSelected)) {
      hasError = true;
      error.push('Selecione a cidade');
    }

    if(companiesSelected.length === 0) {
      hasError = true;
      error.push('Selecione a empresa');
    }

    setAccErrorMessage(error);

    setSubmitDisabled(false);

    if(hasError || !validFile) {
      return;
    }

    // Do the request

    const { status } = await addNewRegister(guid_client, {
      companies: companiesSelected,
      jurisdiction: jurisdictionSelected,
      slug: slugSelected,
      slugStatus: slugStatusSelected,
      idState: stateSelected ?? null,
      idCity: citySelected ?? null,
      origin_name: originName,
      file: fileUploadValue && fileUploadValue.item(0) ? fileUploadValue.item(0) : null
    });

    if (status === 200) {
      toast({
        title: "Relatório adicionado com sucesso.",
        description: "Seu relatório foi adicionada.",
        status: EToastStatus.SUCCESS,
        duration: 5000,
        isClosable: true,
      });

      props.flushHook(true);
      closeModal();
    } else {
      toast({
        title: "Falha ao Adicionar o relatório.",
        description: "Já existe um relatório registrado com esse número.",
        status: EToastStatus.ERROR,
        duration: 5000,
        isClosable: true,
      });
    }
  }

  const closeModal = () => {
    onClose();
  }

  const configState = async () => {
    const states = await stateList();
    setStates(states);
  }

  const configCities = async (idState: number = 1) => {
    const cities = await cityList(idState);
    setCities(cities);
  }

  const configRegionOptions = async () => {
    configState();
    configCities();
  }

  const configCertificateOptions = async (guid_client: string) => {
    const {status, response} = await getClientTaxCurrentAccountIntegrations(guid_client);

    if(status === 200 && response && Array.isArray(response)) {
      setCertificates(response);
      setSlugSelected(response[0].slug);
    }
  }

  const configCertificateStatuses = async (slug: string) => {
    const certificate = certificates.find((info) => info.slug === slug);

    if(certificate && certificate.status) {
      const status = certificate.status.map((info) => ({
        value: info.slug,
        label: info.name
      }));
      
      setCertificateStatuses(status);
    }
  }

  const configCompayOptions = async (guid_client: string) => {
    setLoadingCompany(true);
    const companies = await loadCompanies(guid_client);
    setAllCompanies(companies);
    setLoadingCompany(false);
  }

  useEffect(() => {
    if(certificates && slugSelected) {
      configCertificateStatuses(slugSelected);
    }
  }, [certificates, slugSelected]);

  useEffect(() => {
    if(isOpen && guid_client) {
      configRegionOptions();
      configCertificateOptions(guid_client);
      configCompayOptions(guid_client);

      setCompaniesSelected([]);
      setSlugSelected('');
      setOriginName('');
      setJurisdictionSelected('');
      setSlugStatusSelected('');
      setStateSelected(null);
      setCitySelected(null);
      setFileUploadValue(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, guid_client]);

  useEffect(() => {
    if(stateSelected) {
      configCities(stateSelected);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateSelected]);

  useEffect(() => {
    if (slugSelected !== null && slugSelected !== undefined) {
      configCertificateStatuses(slugSelected);
    }
  }, [slugSelected]);

  return (
    <Flex >
      <ButtonGroup onClick={onOpen} size="sm" mt="24px" isAttached color="white" >
        <Button leftIcon={<AddIcon />} bgColor="#4B4EFF" color="white" _hover={{ bg: '#282be0' }}>
          Cadastro manual
        </Button>
      </ButtonGroup>

      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={closeModal} size="xl" >
        <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" alignItems="center" />
        <ModalContent flexGrow={1} flexShrink={1} borderRadius={8} borderLeft="12px solid #0263FF" >
          <ModalHeader fontFamily="Poppins-Medium">Adicionar Relatório</ModalHeader>
          <ModalCloseButton /> 
          <ModalBody fontFamily="Poppins-Medium">
            {accErrorMessage.length > 0 ? (
              <Flex my="8px" bg="#FFCFCF" color="#A93333" alignItems="flex-start" flexDirection="column" fontSize="12px" p={2} borderRadius={5}>
                <Text>Por favor, informe os campos obrigatórios:</Text>
                {accErrorMessage.map((_x, _i) => (
                  <Flex key={_i}>{_x}</Flex>
                ))}
              </Flex>
            ) : null}
            {simpleErrorMessage ? (
              <Flex bg="#FFCFCF" color="#A93333" alignItems="flex-start" flexDirection="column" fontSize="12px" p={2} borderRadius={5}>
                <Flex>{simpleErrorMessage}</Flex>
              </Flex>
            ) : null}
            <Flex direction="column" gap={2}>
              <Flex direction="column" fontSize="12px" opacity={0.5} mb={4}>
                Cadastre um novo relatório para uma ou mais empresas.
              </Flex>
              
              <Flex height="40px" gap={5}>
                <Flex w="100%" direction="column">
                  {isLoadingCompany ? (
                    <Flex border="1px solid" fontSize="12px" width="auto" w={"100%"} borderColor="gray.300" borderRadius={5} backgroundColor="gray.100" p={2}>
                      Carregando Empresas
                    </Flex>
                  ) :
                    <Flex border="1px solid" fontSize="12px" width="auto" w={"100%"} borderColor="gray.300" borderRadius={5} backgroundColor="gray.100" p={2}>
                      <Link color="#4B4EFF" fontWeight="bold" onClick={() => setOpenSelectCompanies(true)}>{companiesSelected.length > 0 ? companiesSelected.length > 1 ? `${companiesSelected.length} empresas selecionadas` : `${companiesSelected.length} empresa selecionada` : 'Selecione a Empresa'}</Link>
                    </Flex>
                  }
                </Flex>
              </Flex>

              {
                guid_client && allCompanies ? (<SelectCompanies
                  guid_client={guid_client}
                  companies={allCompanies}
                  open={openSelectCompanies}
                  setOpen={setOpenSelectCompanies}
                  selectedCompanies={companiesSelected}
                  setSelectedCompanies={setCompaniesSelected}
                  header="Selecionar Empresas"
                  description=""
                  buttonText="Salvar alteração"
                  isLoading={isLoadingCompany}
                />) : null
              }

              <Flex gap={4} mt="10px">
                <Flex w="50%" flexDirection="column">
                  <CustomAsyncSelect
                    placeholder="Tipo de Relatório"
                    options={certificates.map((info) => ({
                      value: info.slug,
                      label: info.name,
                    }))}
                    setValue={(value: string|number) => setSlugSelected(String(value))}
                    value={slugSelected}
                  />
                </Flex>

                <Flex w="50%" flexDirection="column">
                  <TaxlyInput
                    placeholder="Origem/Órgão"
                    onChange={(event) => {
                      setOriginName(event.target.value);
                    }}
                    value={originName ?? ''}
                  />
                </Flex>
              </Flex>
              
              <Flex gap={4} mt="10px">
                <Flex w="50%" flexDirection="column">
                  <CustomAsyncSelect
                    placeholder="Jurisdição"
                    options={[
                      { value: EJurisdiction.federal, label: 'Federal' },
                      { value: EJurisdiction.state, label: 'Estadual' },
                      { value: EJurisdiction.municipal, label: 'Municipal' },
                    ]}
                    setValue={(value: string | number) => {
                      setJurisdictionSelected(String(value));
                    }}
                    value={jurisdictionSelected}
                  />
                </Flex>
                
                <Flex w="50%" flexDirection="column">
                  <CustomAsyncSelect
                    placeholder="Situação"
                    options={certificateStatuses}
                    setValue={(value: string|number) => {
                      setSlugStatusSelected(String(value));
                    }}
                    value={slugStatusSelected}
                  />
                </Flex>
              </Flex>

              {jurisdictionSelected === 'state' || jurisdictionSelected === 'municipal' ? (
                <Flex gap={4} mt="10px">
                  <Flex w="50%" direction="column">
                    <CustomAsyncSelect
                      placeholder="Selecione o estado"
                      options={states}
                      setValue={(value: string | number) => {
                        setStateSelected(Number(value));
                      }}
                      value={stateSelected}
                    />
                  </Flex>
                  {jurisdictionSelected === 'municipal' ? (
                    <Flex w="50%" direction="column">
                      <CustomAsyncSelect
                        placeholder="Selecione a cidade"
                        options={cities}
                        setValue={(value: string | number) => {
                          setCitySelected(Number(value));
                        }}
                        value={citySelected}
                      />
                    </Flex>
                  ) : null}
                </Flex>
              ) : null}
              
              <Flex alignItems="stretch" gap={2} direction="column" mt={4}>
                {fileUploadValue ? (
                  <Flex>
                    <Text fontSize="12px">{fileUploadValue.item(0)?.name}</Text>
                  </Flex>
                ) : null}
                <FormButton onClick={() => fileUpload.current?.click() }>Carregar arquivo</FormButton>
                <Input ref={fileUpload} type="file" position="absolute" left="-9999" onChange={event => setFileUploadValue(event.target.files as FileList)} />
                <Flex className="situations" direction="column" fontSize="12px" opacity={0.5}>
                  O envio de documentos para registro desse relatório é opcional.
                </Flex>
              </Flex>
            </Flex>
            <Flex alignItems="stretch" direction="column" gap={2} flexGrow={1} mt={8} mb={4} >
              <FormButton onClick={submit} disabled={submitDisabled}>
                Adicionar novo relatório
              </FormButton>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Flex>
  );
};
