import { Flex, Text, Tooltip } from "@chakra-ui/react";
import moment from "moment";
import { useEffect, useState } from "react";
import ReactPlaceholder from "react-placeholder/lib";
import { ICNDResponse } from "../../../../_services/interface/cnd.interface";
import { ICardList } from "../../TaxKanban/interfaces/ICardList";
import { IWidgetIdent } from "../interfaces/IWidget";
import { calcPercent } from "../util/util";
import { WidgetBase } from "./WidgetBase";
import { ECardStatus, ECompanyStatus } from "../../../../_services/enum/irs.enum";

interface ITaxKanbanWidgetProps extends IWidgetIdent {
  data?: ICardList[] | ICNDResponse[];
  isLoading?: boolean;
  startDateFilter?: string;
  endDateFilter?: string;
}

export const TaxKanbanWidget = (props: ITaxKanbanWidgetProps) => {
  const startDateFilter = props.startDateFilter;
  const endDateFilter = props.endDateFilter;
  const [cardsInDue, setCardsInDue] = useState<ICardList[]>([]);
  const [cardsConcluded, setCardsConcluded] = useState<ICardList[]>([]);
  const [cards15Days, setcCards15Days] = useState<ICardList[]>([]);
  const [cards30Days, setcCards30Days] = useState<ICardList[]>([]);
  const [cards7Days, setcCards7Days] = useState<ICardList[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [cardsRest, setCardsRest] = useState<ICardList[]>([]);
  const [qtdFiltered, setQtdFiltered] = useState<number>();

  const changeCardData = () => {
    if (props.data) {
      let cardPool = [...props.data] as ICardList[];
      const dataAtual = new Date();
      const ano = dataAtual.getFullYear().toString();
      const mes = (dataAtual.getMonth() + 1).toString().padStart(2, '0');

      let startDateFilterObject = {
        mes: parseInt(mes),
        ano: parseInt(ano)
      };

      let endDateFilterObject = {
        mes: parseInt(mes),
        ano: parseInt(ano)
      };

      const regexDataAmericana = /^\d{4}-\d{2}-\d{2}$/;
      if (startDateFilter) {
        if (regexDataAmericana.test(startDateFilter)) {
          const [ano, mes] = startDateFilter.split('-');
          startDateFilterObject = {
            mes: parseInt(mes),
            ano: parseInt(ano)
          };
        }
      }

      if (endDateFilter) {      
        if (regexDataAmericana.test(endDateFilter)) {
          const [ano, mes] = endDateFilter.split('-');
          endDateFilterObject = {
            mes: parseInt(mes),
            ano: parseInt(ano)
          };
        }
      }

      const startDate = new Date(startDateFilterObject.ano, startDateFilterObject.mes - 1);
      const endDate = new Date(endDateFilterObject.ano, endDateFilterObject.mes);

      cardPool = cardPool.filter((_c) => {
        const itemDate = new Date(_c.dueDate);
      
        return itemDate >= startDate && itemDate <= endDate;
      });

      //Remove all the cards that are already concluded
      const completedCards: ICardList[] = [];

      cardPool.forEach((x) => {
        let isCompleted = true;
        x.companies.forEach((_c) => {
          if (x.guid_obligation) {
            x.companies.forEach((_c) => {
              if (_c.status !== ECompanyStatus.concluded) {
                isCompleted = false;
              }
            });
          } else if (x.guid_card_structure) {
            if (x.status !== ECardStatus.DONE) {
              isCompleted = false
            }
          }
        });

        if (isCompleted) {
          completedCards.push(x);
        }
      });

      setCardsConcluded(completedCards);
      cardPool = cardPool.filter((_x) => !completedCards.map((x) => x.guid_card).includes(_x.guid_card));
      //Now remove all cards that are in due
      const cardDue = cardPool.filter((_x) => moment.utc(_x.dueDate).diff(moment.utc(), 'days') < 0);
      setCardsInDue(cardDue);
      cardPool = cardPool.filter((_x) => moment.utc(_x.dueDate).diff(moment.utc(), 'days') >= 0);

      const card7days = cardPool.filter((_x) => moment.utc(_x.dueDate).diff(moment.utc(), 'days') < 7);
      //Now remove all cards that are less than 7 days
      setcCards7Days(card7days);
      cardPool = cardPool.filter((_x) => moment.utc(_x.dueDate).diff(moment.utc(), 'days') >= 7);

      //Now remove all cards that are less than 15 days
      const card15days = cardPool.filter((_x) => moment.utc(_x.dueDate).diff(moment.utc(), 'days') < 15);
      setcCards15Days(card15days);
      cardPool = cardPool.filter((_x) => moment.utc(_x.dueDate).diff(moment.utc(), 'days') >= 15);

      //Now remove all cards that are less than 30 days
      const card30Days = cardPool.filter((_x) => moment.utc(_x.dueDate).diff(moment.utc(), 'days') < 30);
      setcCards30Days(card30Days);
      cardPool = cardPool.filter((_x) => moment.utc(_x.dueDate).diff(moment.utc(), 'days') >= 30);

      setQtdFiltered(completedCards.length + cardDue.length + card7days.length + card15days.length + card30Days.length);
      setCardsRest(cardPool);
    }
  }

  useEffect(() => {
    changeCardData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data]);

  if (props.id && props.index !== undefined && props.data) {
    return (
      <WidgetBase title="Status das tarefas" id={props.id} index={props.index} >
        <Flex flexDirection="column" width="100%" gap={4} >
          <Flex alignItems="center" gap={4}>
            
            <ReactPlaceholder showLoadingAnimation type='text' ready={!props.isLoading} rows={1} style={{width: '80px'}}>
              <Text fontSize="14px" width="100px" color="Black">Atrasado</Text>
            </ReactPlaceholder>

            <ReactPlaceholder showLoadingAnimation type='text' ready={!props.isLoading} rows={1}>
              <Tooltip label={`${cardsInDue.length} Cards`}>
                <Flex bg="gray.100" height="12px" width="100%" borderRadius="50px">
                  <Flex bg="#4B4EFF" height="12px" width={qtdFiltered ? `${calcPercent(cardsInDue.length, qtdFiltered)}%` : 0} transition="500ms" borderRadius="50px"></Flex>
                </Flex>
              </Tooltip>
              <Text fontSize="14px" width="50px" color="black">{qtdFiltered ? `${calcPercent(cardsInDue.length, qtdFiltered)}%` : 0}</Text>
            </ReactPlaceholder>
          </Flex>

          <Flex alignItems="center" gap={4}>
            <ReactPlaceholder showLoadingAnimation type='text' ready={!props.isLoading} rows={1} style={{width: '80px'}}>
              <Text fontSize="14px" width="100px" color="Black">em 30 dias</Text>
            </ReactPlaceholder>

            <ReactPlaceholder showLoadingAnimation type='text' ready={!props.isLoading} rows={1}>
              <Tooltip label={`${cards30Days.length} Cards`}>
                <Flex bg="gray.100" height="12px" width="100%" borderRadius="50px">
                  <Flex bg="#4B4EFF" height="12px" width={qtdFiltered ? `${calcPercent(cards30Days.length, qtdFiltered)}%` : 0} transition="500ms" borderRadius="50px"></Flex>
                </Flex>
              </Tooltip>
              <Text fontSize="14px" width="50px" color="black">{qtdFiltered ? `${calcPercent(cards30Days.length, qtdFiltered)}%` : 0}</Text>
            </ReactPlaceholder>
          </Flex>

          <Flex alignItems="center" gap={4}>
            <ReactPlaceholder showLoadingAnimation type='text' ready={!props.isLoading} rows={1} style={{width: '80px'}}>
              <Text fontSize="14px" width="100px" color="Black">em 15 dias</Text>
            </ReactPlaceholder>

            <ReactPlaceholder showLoadingAnimation type='text' ready={!props.isLoading} rows={1}>
              <Tooltip label={`${cards15Days.length} Cards`}>
                <Flex bg="gray.100" height="12px" width="100%" borderRadius="50px">
                  <Flex bg="#4B4EFF" height="12px" width={qtdFiltered ? `${calcPercent(cards15Days.length, qtdFiltered)}%` : 0} transition="500ms" borderRadius="50px"></Flex>
                </Flex>
              </Tooltip>
              <Text fontSize="14px" width="50px" color="black">{qtdFiltered ? `${calcPercent(cards15Days.length, qtdFiltered)}%` : 0}</Text>
            </ReactPlaceholder>
          </Flex>

          <Flex alignItems="center" gap={4}>
            <ReactPlaceholder showLoadingAnimation type='text' ready={!props.isLoading} rows={1} style={{width: '80px'}}>
              <Text fontSize="14px" width="100px" color="Black">em 7 dias</Text>
            </ReactPlaceholder>

            <ReactPlaceholder showLoadingAnimation type='text' ready={!props.isLoading} rows={1}>
              <Tooltip label={`${cards7Days.length} Cards`}>
                <Flex bg="gray.100" height="12px" width="100%" borderRadius="50px">
                  <Flex bg="#4B4EFF" height="12px" width={qtdFiltered ? `${calcPercent(cards7Days.length, qtdFiltered)}%` : 0} transition="500ms" borderRadius="50px"></Flex>
                </Flex>
              </Tooltip>
              <Text fontSize="14px" width="50px" color="black">{qtdFiltered ? `${calcPercent(cards7Days.length, qtdFiltered)}%` : 0}</Text>
            </ReactPlaceholder>
          </Flex>

          <Flex alignItems="center" gap={4}>
            <ReactPlaceholder showLoadingAnimation type='text' ready={!props.isLoading} rows={1} style={{width: '80px'}}>
              <Text fontSize="14px" width="100px" color="Black">Concluído</Text>
            </ReactPlaceholder>

            <ReactPlaceholder showLoadingAnimation type='text' ready={!props.isLoading} rows={1}>
              <Tooltip label={`${cardsConcluded.length} Cards`}>
                <Flex bg="gray.100" height="12px" width="100%" borderRadius="50px">
                  <Flex bg="#4B4EFF" height="12px" width={qtdFiltered ? `${calcPercent(cardsConcluded.length, qtdFiltered)}%` : 0} transition="500ms" borderRadius="50px"></Flex>
                </Flex>
              </Tooltip>
              <Text fontSize="14px" width="50px" color="black">{qtdFiltered ? `${calcPercent(cardsConcluded.length, qtdFiltered)}%` : 0}</Text>
            </ReactPlaceholder>
          </Flex>
        </Flex>
      </WidgetBase>
    );
  } else {
    return null;
  }
}