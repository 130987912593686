import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, useDisclosure } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Datatables } from "../../../../components/Table/Datatables";
import { IColumn, IData, IDataMeta, ITableMetaData } from "../../../../components/Table/table.interface";
import { IDigitalCertificate, IDigitalCertificateCompany } from "../../../../_services/interface/digitalCertificate.interface";
import { companyHolder } from "../util/DigitalCertificate";

interface IProps {
  digitalCertificate: IDigitalCertificate|null;
  setIsOpen: (value: boolean) => void;
  isOpen: boolean;
}

export const DisplayRelationsCompanies = (props: IProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [companies, setCompanies] = useState<IDigitalCertificateCompany[]>([]);
  const [metaData, setMetaData] = useState<ITableMetaData|undefined>();
  const [companiesList, setCompaniesList] = useState<IData<IDigitalCertificateCompany>|null>(null);

  const columns = [
    {
      title: 'Empresa - cód',
      name: 'displayName',
      sortable: false,
      align: 'center',
    },
    {
      title: 'CNPJ',
      name: 'cnpjFormatted',
      sortable: false,
      align: 'center',
    },
    {
      title: 'Empresa titular',
      name: 'holder',
      sortable: false,
      align: 'center',
      dynamicTag: {
        function: companyHolder
      },
    },
  ] as IColumn[];

  const filterCompaniesList = () => {
    if (companies) {
      const data = companies.map((company) => {
        return {
          guid_company: company.guid_company,
          displayName: company.displayName,
          cnpjFormatted: company.formattedCnpj,
          holder: company.holder,
          name: company.name,
          cnpj: company.cnpj,
        } as IDigitalCertificateCompany;
      });

      const totalPages: number = Math.round(data.length/10);
  
      let meta: IDataMeta = {
        currentPage: metaData?.currentPage || 1,
        isFirstPage: true,
        isLastPage: totalPages === 1,
        nextPage: totalPages > 1 ? 2 : null,
        pageCount: totalPages,
        previousPage: null,
        totalCount: data.length,
      }
  
      let currentRowByPage = metaData?.rowByPage || 10;
  
      const start = (meta.currentPage - 1) * currentRowByPage;
      const end = start + currentRowByPage;
      const paginatedData = data.slice(start, end);
      
      setCompaniesList({
        meta: meta,
        data: paginatedData
      })
    }
  }

  useEffect(() => {
    if (props.isOpen) {
      onOpen();
      props.setIsOpen(false);
    }
  }, [props.isOpen]);

  useEffect(() => {
    if (props.digitalCertificate?.companies) {
      setIsLoading(true);
      setCompanies(props.digitalCertificate.companies);
      setIsLoading(false);
    }
  }, [props.digitalCertificate]);

  useEffect(() => {
    filterCompaniesList();
  }, [metaData]);

  return (
    <Modal closeOnOverlayClick={false} isCentered isOpen={isOpen} onClose={onClose} size="4xl">
      <ModalOverlay bg='blackAlpha.300' backdropFilter='blur(10px)' alignItems="center" />
        
      <ModalContent flexGrow={1} flexShrink={1} borderLeft="12px solid #0263FF" borderRadius={8}>
        <ModalHeader fontFamily="Poppins-SemiBold">
          Empresas Vinculadas
        </ModalHeader>

        <ModalCloseButton />

          <ModalBody fontFamily="Poppins-medium" fontSize={14} color="#171923">
            <Datatables
              name="Empresas Vinculadas"
              columns={columns}
              metaDataHook={setMetaData}
              data={companiesList}
              isLoading={isLoading}
            />
          </ModalBody>

          <ModalFooter>
            <Text 
              color="#171923" 
              fontSize="14px" 
              fontWeight="500"
              marginRight="45px"
              cursor="pointer"
              onClick={onClose}
            >
              Fechar
            </Text>
          </ModalFooter>
      </ModalContent>
    </Modal>
  );
}