import * as Yup from "yup";
import moment from "moment";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormButton } from "../../../../components/login/button.component";
import { IDigitalCertificate } from "../../../../_services/interface/digitalCertificate.interface";
import { DashFileInput, DashInput, DashInputPassword } from "../../Users/components/DashInput";
import { getCertificateByCompany, patchEditCertificate, postCreateCertificate, postValidCertificate } from "../../../../_services/digitalCertificate.service";
import { useDisclosure, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Text, ModalFooter, Flex, Image, useToast, Button, ButtonGroup, InputLeftElement, InputGroup, Link } from "@chakra-ui/react";
import { useClientContext } from "../../../../components/sidebar";
import { AddIcon } from "@chakra-ui/icons";
import { Option } from "../../../../components/MultiSelectWithCheckbox";
import { formatCnpj } from "../../Companies/Company/util/cnpj";
import SingleSelect from "../../../../components/SingleSelect";
import { IClientCompanies } from "../../../../_services/interface/company.interface";
import { getClientCompanies } from "../../../../_services/company.service";
import { Validation2fa } from "../../../../modal/Validation2fa";
import { SelectCompanies } from "../../../../modal/SelectCompanies";

interface IProps {
  setRefreshTable: (value: boolean) => void;
  guid_company: null|string;
  setGuidCompany: (value: null|string) => void;
  setIsOpen: (value: boolean) => void;
  isOpen: boolean;
}

interface IForm {
  guid_company: string;
  companies: string[];
  certificate_password: string;
  certificate_file: FileList;
  valid_until_date: string;
  emission_date: string;
}

export const CreateDigitalCertificateModal = (props: IProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const useDisclosure2FAModal = useDisclosure();
  const { guid_client } = useClientContext();
  const toast = useToast();

  const YupChangeForm = Yup.object().shape({
    guid_company: Yup.string().required("Empresa Titular é obrigatória"),
    companies: Yup.array().of(Yup.string()),
    certificate_password: Yup.string(),
    certificate_file: Yup.mixed(),
    valid_until_date: Yup.string(),
    emission_date: Yup.string(),
  });
  const resolverForm = { resolver: yupResolver(YupChangeForm) };
  const { formState: { errors }, register, watch, getValues, setValue, handleSubmit, reset, resetField } = useForm<IForm>(resolverForm);
  const [isActive, setIsActive] = useState<boolean>(true);
  const [disabledSaveButton, setDisabledSaveButton] = useState<boolean>(true);

  //Companies
  const [optionsCompanies, setOptionsCompanies] = useState<Option[]>([]);
  const [companies, setCompanies] = useState<IClientCompanies[]>([]);
  const [isLoadingCompanies, setIsLoadingCompanies] = useState<boolean>(false);
  const [companyChecked, setCompanyChecked] = useState<string[]>([]);
  const [openSelectCompanies, setOpenSelectCompanies] = useState<boolean>(false);

  const [digitalCertificate, setDigitalCertificate] = useState<IDigitalCertificate>();

  // Pronto para ser validado
  const [certificateReadyForValidate, setCertificateReadyForValidate] = useState<boolean>(false);

  //Validando
  const [certificateValidating, setCertificateValidating] = useState<boolean>(false);

  //Validado
  const [certificateValidated, setCertificateValidated] = useState<boolean>(false);

  //Validado e invalido
  const [certificateValidatedFaill, setCertificateValidatedFaill] = useState<boolean>(false);

  //Validado e expirado
  const [certificateValidatedExpired, setCertificateValidatedExpired] = useState<boolean>(false);

  //Salvando
  const [certificateSaving, setCertificateSaving] = useState<boolean>(false);

  const getCompaniesOptions = async () => {
    if (guid_client) {
      setIsLoadingCompanies(true);
      const { status, response } = await getClientCompanies(guid_client, true);

      if (status === 200) {
        const companyOptionsList: Option[] = [];

        if (Array.isArray(response)) {
          const responseCompanies = response as IClientCompanies[];
          setCompanies(responseCompanies);

          responseCompanies.forEach(companyOption => {
            companyOptionsList.push({ 
              label: `${companyOption.name} - ${formatCnpj(companyOption.cnpj)}`,
              value: companyOption.guid_company,
            });
          });

          setOptionsCompanies(companyOptionsList.sort((a, b) => a.label.localeCompare(b.label)));
        } else {
          toast({
            title: 'Ocorreu um erro',
            description: 'Ocorreu um erro ao tentar obter as informações das empresas',
            status: 'error',
            duration: 5000,
            isClosable: true
          });
        }        
      }
      setIsLoadingCompanies(false);
    }

  }

  const saveCertificate = async (guid_client: string, data: IForm) => {
    const { status, response } = await postCreateCertificate(guid_client, data.guid_company, {
      name: data.certificate_file.item(0)?.name as string,
      file: data.certificate_file.item(0) as File,
      password: data.certificate_password,
      is_active: true,
      companies: data.companies,
    });

    if (status === 200 && 'guid_digital_certificate' in response && response.guid_digital_certificate) {
      onClose();
      toast({
        title: 'Certificado Digital criado com sucesso',
        description: 'Seu certificado digital foi criado com sucesso e já está disponível para uso',
        status: 'success',
        duration: 5000,
        isClosable: true
      });
      props.setRefreshTable(true);
    } else {
      toast({
        title: 'Ocorreu um erro ao criar o certificado digital',
        description: 'Ocorreu um erro ao tentar criar o certificado digital, por favor, tente novamente mais tarde',
        status: 'error',
        duration: 5000,
        isClosable: true
      });
    }
  }

  const editCertificate = async (data: IForm) => {
      if(!digitalCertificate || !guid_client || !props.guid_company) {
        return false;
      }

      const { status, response } = await patchEditCertificate(guid_client, props.guid_company, digitalCertificate.guid_digital_certificate, {
          name: data.certificate_file.item(0)?.name as string,
          file: data.certificate_file.item(0) as File,
          password: data.certificate_password,
          is_active: isActive,
          companies: data.companies ?? [],
      });

      if (status === 200 && 'guid_digital_certificate' in response && response.guid_digital_certificate) {
          onClose();
          props.setRefreshTable(true);
          toast({
            title: 'Certificado Digital editado com sucesso',
            description: 'Seu certificado digital foi editado com sucesso e já está disponível para uso',
            status: 'success',
            duration: 5000,
            isClosable: true
          });
      } else {
          toast({
            title: 'Ocorreu um erro ao editar o certificado digital',
            description: 'Ocorreu um erro ao tentar editar o certificado digital, por favor, tente novamente mais tarde',
            status: 'error',
            duration: 5000,
            isClosable: true
          });
      }
  }

  const configCertificateForEdit = async (guid_company: string) => {
    if(!guid_client) {
      return;
    }

    const { status, response } = await getCertificateByCompany(guid_client, guid_company);
    if (status === 200 && typeof response === 'object' && 'guid_digital_certificate' in response) {
      setDigitalCertificate(response as IDigitalCertificate);
    }
  }

  const handleFormInfo = async (data: IForm) => {
    setCertificateSaving(true);
    if(guid_client) {
      if(digitalCertificate) {
        editCertificate(data);
      } else {
        saveCertificate(guid_client, data);
      }
    }
    setCertificateSaving(false);
  };

  const validCertificate = async (guid_client: string) => {
    setCertificateValidating(true);
    setCertificateValidated(false);
    setCertificateValidatedFaill(false);
    setCertificateValidatedExpired(false);
    setValue('valid_until_date', '');
    setValue('emission_date', '');

    const { certificate_file, certificate_password } = getValues();

    if(!certificate_file.item(0) || !certificate_password) {
      setCertificateValidating(false);
      return;
    }

    const MAX_WAIT_TIME = 5000; // 5 segundos em milissegundos
    const startExecutionTime = Date.now();

    const { status, response } = await postValidCertificate(guid_client, {
      file: certificate_file.item(0) as File,
      password: certificate_password,
    });

    const elapsedTime = Date.now() - startExecutionTime;
    const remainingTime = Math.max(0, MAX_WAIT_TIME - elapsedTime);

    if (remainingTime > 0) {
      await new Promise(resolve => setTimeout(resolve, remainingTime));
    }

    if (status === 200) {
      if ('validate' in response && response.validate) {
        const expiration_date = response.expiration_date ? moment(response.expiration_date).format('DD/MM/YYYY') : '';
        const issued_date = response.issued_date ? moment(response.issued_date).format('DD/MM/YYYY') : '';

        setValue('valid_until_date', expiration_date);
        setValue('emission_date', issued_date);

        const currentDate = new Date();
        const validUntilDate = response.expiration_date ? new Date(response.expiration_date) : null;
        const isExpired = validUntilDate && validUntilDate < currentDate;

        if (isExpired) {
          setCertificateValidatedExpired(true);
        } else {
          setCertificateValidated(true);
        }
      }

      if ('validate' in response && !response.validate) {
        setCertificateValidatedFaill(true);
      }
    } else {
      setCertificateValidatedFaill(true);
    }

    setCertificateValidating(false);
  }

  const changeCompanyOwner = (selectedOption: Option[]) => {
    if(selectedOption[0]) {
      setValue('guid_company', String(selectedOption[0].value));
      setCompanyChecked(companyChecked.filter((company) => company !== String(selectedOption[0].value)));
    } else {
      setValue('guid_company', '');
    }
  }

  const openModal = (code: string) => {
    if(code) {
      onOpen();
    }
  }

  useEffect(() => {
    setValue('companies', companyChecked);
  }, [companyChecked]);

  // disable save button
  useEffect(() => {
    setDisabledSaveButton(false);

    // Esta salvando
    if(certificateSaving) {
      setDisabledSaveButton(true);
    }

    const { certificate_file, certificate_password } = getValues();

    // Caso tenha alteração de arquivo ou senha e não foi validado ainda
    if(!certificateValidated) {
      if(certificate_file && certificate_file.length > 0) {
        setDisabledSaveButton(true);
      }

      if(certificate_password && certificate_password.length > 0) {
        setDisabledSaveButton(true);
      }
    }

    if(!certificateValidated && !digitalCertificate) {
      setDisabledSaveButton(true);
    }
  }, [watch('certificate_file'), watch('certificate_password'), certificateSaving, certificateValidated, digitalCertificate]);

  // Em caso de edição, preenche os campos do formulário
  useEffect(() => {
    if (digitalCertificate) {
      const issuedDate = digitalCertificate.issued_date ? moment(digitalCertificate.issued_date).format('YYYY-MM-DD') : '';
      const expirationDate = digitalCertificate.expiration_date ? moment(digitalCertificate.expiration_date).format('YYYY-MM-DD') : '';

      if(expirationDate){
        setValue('valid_until_date', expirationDate);
      }

      if(issuedDate) {
        setValue('emission_date', issuedDate);
      }

      setIsActive(digitalCertificate.is_active);

      setValue('guid_company', digitalCertificate.companyOwner.guid_company);

      setCompanyChecked(digitalCertificate.companies.map((company) => company.guid_company));
    }
  }, [digitalCertificate]);

  // Caso tenha alteração do arquivo ou senha, solicita nova validação
  useEffect(() => {
    setCertificateReadyForValidate(false);
    setCertificateValidated(false);

    const { certificate_password, certificate_file } = getValues();
    if(certificate_password && certificate_file && certificate_file.length === 1) {
      setCertificateReadyForValidate(true);
    }
  }, [watch('certificate_password'), watch('certificate_file')]);

  useEffect(() => {
    if (isOpen) {
      setCertificateValidating(false);
      setCertificateValidated(false);
      setCertificateValidatedFaill(false);
      setCertificateValidatedExpired(false);
      setDigitalCertificate(undefined);
      setCompanyChecked([]);

      getCompaniesOptions();

      // Yup Form reset
      reset();

      if (props.guid_company) {
        configCertificateForEdit(props.guid_company);
      }
    }
  }, [isOpen]);

  useEffect(() => {
    if(props.isOpen) {
      useDisclosure2FAModal.onOpen();
      props.setIsOpen(false);
    }
  }, [props.isOpen]);

  return (
    <>
      <ButtonGroup onClick={() => {useDisclosure2FAModal.onOpen();props.setGuidCompany(null)}} size="sm" mt="24px" isAttached color="white" >
        <Button leftIcon={<AddIcon />} bgColor="#4B4EFF" color="white" _hover={{ bg: '#282be0' }}>
         Cadastrar certificado digital
        </Button>
      </ButtonGroup>

      <Modal closeOnOverlayClick={false} isCentered isOpen={isOpen} onClose={onClose} size="4xl">
        <ModalOverlay bg='blackAlpha.300' backdropFilter='blur(10px)' alignItems="center" />
          
        <ModalContent flexGrow={1} flexShrink={1} borderLeft="12px solid #0263FF" borderRadius={8}>
          <ModalHeader fontFamily="Poppins-SemiBold">
            { digitalCertificate ? 'Editar certificado digital' : 'Criar novo certificado digital'}
          </ModalHeader>

          <ModalCloseButton />

            <ModalBody fontFamily="Poppins-medium" fontSize={14} color="#171923">
              <SingleSelect
                key={'company-owner'}
                options={optionsCompanies}
                onChange={changeCompanyOwner}
                value={!getValues('guid_company') ? [] : [{label: optionsCompanies?.find((company) => company && company.value !== undefined && String(company.value) === getValues('guid_company'))?.label || '', value: getValues('guid_company')}]}
                label={optionsCompanies.length > 0 ? 'Empresa Titular' : 'Carregando empresas...'}
                isSelectAll={false}
                menuPlacement={"bottom"}
                isDisabled={digitalCertificate ? true : false}
                fontSize="12px"
              />

              <Flex marginTop="24px" justifyContent="space-between" alignItems="end">
                <Flex justifyContent="space-between" width="100%" alignItems="end">
                  <Flex width="39%" flexDirection="column">
                    <Flex>
                      <Text fontFamily="Poppins-Medium" fontSize={12}>
                        Certificado digital
                      </Text>
                      <Text color="red">*</Text>
                    </Flex>

                    <DashFileInput 
                      label="Escolher arquivo" 
                      accept=".pfx" 
                      style={{
                          fontFamily: "Poppins-medium", 
                          fontSize: 14, 
                          fontWeight: 400,
                      }}
                      {...register("certificate_file")}
                    />
                  </Flex>

                    <Flex width="39%" flexDirection="column">
                      <Flex>
                        <Text fontFamily="Poppins-Medium" fontSize={12}>
                          Senha do certificado digital
                        </Text>
                        <Text color="red">*</Text>
                      </Flex>

                      <DashInputPassword {...register("certificate_password")} />
                  </Flex>

                  <Flex width="19%" flexDirection="column">
                    <FormButton
                      width="100%"
                      height="39px"
                      isLoading={certificateValidating}
                      disabled={!certificateReadyForValidate || certificateValidating}
                      onClick={() => guid_client ? validCertificate(guid_client) : null}
                    >
                      Validar certificado
                    </FormButton>
                  </Flex>
                </Flex>
              </Flex>

              {
                (
                  certificateValidating ||
                  certificateValidatedFaill ||
                  certificateValidatedExpired ||
                  certificateValidated
                ) && (
                  <Flex mt="10px">
                    {
                      certificateValidating ? 
                        <>
                          <Image src="/icons/loading-blue.svg" className="rotate-forever" alt="Loading" marginRight="3px" />
                          <Text
                            fontSize={12}
                            color="#4B4EFF"
                          >
                            Validando certificado
                          </Text>
                        </>
                      : null
                    }

                    {
                      !certificateValidating && certificateValidatedFaill ? 
                        <>
                          <Image src="/icons/icon-close-red.svg" alt="Close Red" marginRight="3px" />
                          <Text
                            fontSize={12}
                            color="#DC2626"
                          >
                            Certificado inválido
                          </Text>
                        </>
                      : null
                    }

                    {
                      !certificateValidating && certificateValidatedExpired ? 
                        <>
                          <Image src="/icons/icon-close-red.svg" alt="Close Red" marginRight="3px" />
                          <Text
                            fontSize={12}
                            color="#DC2626"
                          >
                            Certificado vencido
                          </Text>
                        </>
                      : null
                    }

                    {
                      !certificateValidating && certificateValidated ? 
                        <>
                          <Image src="/icons/check-green.svg" alt="Close Red" marginRight="3px" />
                          <Text
                            fontSize={12}
                            color="#16A34A"
                          >
                            Certificado validado
                          </Text>
                        </>
                      : null
                    }
                  </Flex>
                )
              }

              <Flex marginTop="24px" justifyContent="space-between" alignItems="end">
                <Flex justifyContent="space-between" width="100%" alignItems="end">
                  <Flex width="49%" flexDirection="column">
                    <label
                      style={{
                        fontSize: '12px',
                        fontWeight: 500,
                        fontFamily: 'Poppins-Medium',
                        marginBottom: '5px',

                      }}
                    >
                      Data de emissão
                    </label>

                    <InputGroup
                      w="100%"
                      border="1px solid var(--chakra-colors-chakra-border-color)"
                      borderRadius={6}
                    >
                      <Flex flexDirection="column" w="100%" cursor={'not-allowed'}>
                        <InputGroup>
                          <Flex flexDirection="column" w="100%">
                            <InputLeftElement
                              height={8}
                              pointerEvents="none"
                              children={<Image src="/icons/calender.svg" />}
                              style={{ cursor: 'not-allowed'}}
                            />

                            <DashInput
                              disabled={true}
                              pl="40px"
                              type="text"
                              isReadOnly
                              style={{ cursor: 'not-allowed'}}
                              border="none"
                              borderBottom="none"
                              borderRadius="3px"
                              height="32px"
                              placeholder={'__/__/____'}
                              fontSize="12px"
                              {...register('emission_date')}
                              position={"relative"}
                            />
                          </Flex>
                        </InputGroup>
                      </Flex>
                    </InputGroup>
                  </Flex>

                  <Flex width="49%" flexDirection="column">
                    <label
                      style={{
                        fontSize: '12px',
                        fontWeight: 500,
                        fontFamily: 'Poppins-Medium',
                        marginBottom: '5px',

                      }}
                    >
                      Data de vencimento
                    </label>

                    <InputGroup
                      w="100%"
                      border="1px solid var(--chakra-colors-chakra-border-color)"
                      borderRadius={6}
                    >
                      <Flex flexDirection="column" w="100%" cursor={'not-allowed'}>
                        <InputGroup>
                          <Flex flexDirection="column" w="100%">
                            <InputLeftElement
                              height={8}
                              pointerEvents="none"
                              children={<Image src="/icons/calender.svg" />}
                              style={{ cursor: 'not-allowed'}}
                            />

                            <DashInput
                              disabled={true}
                              pl="40px"
                              type="text"
                              isReadOnly
                              style={{ cursor: 'not-allowed'}}
                              border="none"
                              borderBottom="none"
                              borderRadius="3px"
                              height="32px"
                              placeholder={'__/__/____'}
                              fontSize="12px"
                              {...register('valid_until_date')}
                              position={"relative"}
                            />
                          </Flex>
                        </InputGroup>
                      </Flex>
                    </InputGroup>
                  </Flex>
                </Flex>
              </Flex>

              <Flex marginTop="34px" justifyContent="space-between" alignItems="end">
                <Text
                  color="#4B4EFF"
                  fontFamily="Poppins-Medium"
                  fontSize="12px"
                  fontStyle="normal"
                  fontWeight="500"
                  lineHeight="normal"
                  onClick={() => setOpenSelectCompanies(true)}
                  cursor="pointer"
                >
                  {companyChecked.length > 0 ? companyChecked.length > 1 ? `${companyChecked.length} Empresas com Procuração` : `${companyChecked.length} Empresa com Procuração` : 'Adicionar Procuração'}
                </Text>
              </Flex>
            </ModalBody>

            <ModalFooter>
              <Flex gap={2}>
                <FormButton 
                  onClick={onClose}
                  color="white"
                  textColor="#4B4EFF"
                  border="1px solid #4B4EFF"
                >
                  Fechar
                </FormButton>

                <FormButton 
                  isLoading={certificateSaving}
                  disabled={disabledSaveButton}
                  onClick={handleSubmit(handleFormInfo)}
                >
                  Salvar
                </FormButton>
              </Flex>
            </ModalFooter>

            {
              guid_client ? (
                <SelectCompanies
                  guid_client={guid_client}
                  companies={companies}
                  open={openSelectCompanies}
                  setOpen={setOpenSelectCompanies}
                  selectedCompanies={companyChecked}
                  setSelectedCompanies={setCompanyChecked}
                  header="Selecionar Empresas"
                  description=""
                  buttonText="Salvar alteração"
                  isLoading={isLoadingCompanies}
                />
              ) : null
            }
        </ModalContent>
      </Modal>

      <Validation2fa 
        callback={openModal}
        disclosureProps={useDisclosure2FAModal}
      />
    </>
  );
}