import { ApiAuth, ApiCall } from "./api";
import { TPremiseResponse } from "./interface/IDefaults";
import { IDigitalCertificate, IDIgitalCertificateFilePassword, IDIgitalCertificateCreateEdit, IDigitalCertificateValidateResponse, IDigitalCertificateList, IFilterDigitalCertificate } from "./interface/digitalCertificate.interface";

export const postValidCertificate = async(guid_client: string, formdata: IDIgitalCertificateFilePassword): Promise<TPremiseResponse<IDigitalCertificateValidateResponse>> => {
  return await new ApiCall(`/digital-certificate/${guid_client}`, ApiAuth({ "Content-Type": "multipart/form-data" })).post<any, IDIgitalCertificateFilePassword>(formdata);
}

export const postCreateCertificate = async(guid_client: string, guid_company: string, formdata: IDIgitalCertificateCreateEdit): Promise<TPremiseResponse<IDigitalCertificate>> => {
  return await new ApiCall(`/digital-certificate/${guid_client}/${guid_company}`, ApiAuth({ "Content-Type": "multipart/form-data" })).post<any, IDIgitalCertificateCreateEdit>(formdata);
}

export const patchEditCertificate = async(guid_client: string, guid_company: string, guid_digital_certificate: string, formdata: IDIgitalCertificateCreateEdit): Promise<TPremiseResponse<IDigitalCertificate>> => {
  return await new ApiCall(`/digital-certificate/${guid_client}/${guid_company}/${guid_digital_certificate}`, ApiAuth({ "Content-Type": "multipart/form-data" })).patch<any, IDIgitalCertificateCreateEdit>(formdata);
}

export const patchActiveCertificate = async(guid_client: string, guid_digital_certificate: string, active: 1|0): Promise<TPremiseResponse<IDigitalCertificate>> => {
  return await new ApiCall(`/digital-certificate/${guid_client}/active/${guid_digital_certificate}/${active}`, ApiAuth({ "Content-Type": "multipart/form-data" })).patch<any, any>();
}

export const getCertificateByCompany = async(guid_client: string, guid_company: string): Promise<TPremiseResponse<IDigitalCertificate>> => {
  return await new ApiCall(`/digital-certificate/company/${guid_client}/${guid_company}`, ApiAuth()).get<any>();
}

export const getCertificate = async(guid_client: string, guid_digital_certificate: string): Promise<TPremiseResponse<IDigitalCertificate>> => {
  return await new ApiCall(`/digital-certificate/${guid_client}/${guid_digital_certificate}`, ApiAuth()).get<any>();
}

export const deleteCertificate = async(guid_client: string, guid_digital_certificate: string): Promise<TPremiseResponse<boolean>> => {
  return await new ApiCall(`/digital-certificate/${guid_client}/${guid_digital_certificate}`, ApiAuth()).delete<any>();
}

export const listDigitalCertificate = async(guid_client: string, filters: IFilterDigitalCertificate): Promise<TPremiseResponse<IDigitalCertificateList>> => {
  return await new ApiCall(`/digital-certificate/list/${guid_client}`, ApiAuth()).post<any, IFilterDigitalCertificate>(filters);
}