import { Flex, Text } from "@chakra-ui/react"
import { ICNDCertificateExpirationQtdByMonth, ICNDMacroData } from "../../../_services/interface/cnd.interface";
import moment from "moment";
import { ICertificate } from "../../../_services/interface/certificate.interface";
import { Chart } from "react-google-charts";
import ReactPlaceholder from 'react-placeholder';
import "react-placeholder/lib/reactPlaceholder.css";
import { RectShape, RoundShape } from "react-placeholder/lib/placeholders";
import useLoginFormStore from "../../../store/useLoginFormStore";
import { useEffect, useState } from "react";
import { IClientDataResponse } from "../../../_services/interface/client.interface";
import { GraphStatus } from "./CertificateManagerPanel";

interface IAddCNDnModalProps {
  cndMacroData: ICNDMacroData|null;
  timeSaving: string;
  certificate: ICertificate;
  isLoading: boolean;
  visualizationType?: string;
  guid_client: string;
  setGraphStatus: React.Dispatch<React.SetStateAction<GraphStatus | null>>;
}

export const CNDManagerDashboard = (props: IAddCNDnModalProps) => {
  const { validate } = useLoginFormStore();
  const [currentClient, setCurrentClient] = useState<IClientDataResponse|null>(null);

  useEffect(() => {
    validate?.clients.map((client: any) => {
      if (client.guid_client === props.guid_client) {
        setCurrentClient(client);
      }
    });
  }, [props.guid_client]);

  const refreshChartData = (
    certificatesQtd: number|undefined,
    certificatesValidQtd: number|undefined,
    certificatesErrorQtd: number|undefined,
    certificatesNoRegistryQtd: number|undefined
  ) => {

    const statusOptions = props.certificate.status ? props.certificate.status : [];
    const statusWithPendency = statusOptions.find((x) => x.slug === 'pendency') ?? {name: "Não Emitido"};
    const statusWithoutPendency = statusOptions.find((x) => x.slug === 'not-pendency')  ?? {name: "Emitido"};

    let statusWithPendencyName = statusWithPendency.name.toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toLocaleUpperCase() + word.slice(1))
      .join(' ');

    let statusWithoutPendencyName = statusWithoutPendency.name.toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toLocaleUpperCase() + word.slice(1))
      .join(' ');

    if(currentClient && !currentClient.customStatusForCertificate) {
      statusWithPendencyName = 'Inválido';
      statusWithoutPendencyName = 'Válido';
    }
    const certsQtd = certificatesQtd ?? 0;
    const validQtd = certificatesValidQtd ?? 0;
    const noRegistryQtd = certificatesNoRegistryQtd ?? 0;
    const errorQtd = certificatesErrorQtd ?? 0;
    const pendencyQtd = certsQtd - (validQtd + noRegistryQtd + errorQtd);

    return [
      ['Emissões', 'CNDs'],
      [statusWithoutPendencyName, validQtd],
      [statusWithPendencyName, pendencyQtd],
      ['Erros', errorQtd],
      ['Sem Registro', noRegistryQtd]
    ];
  }

  const refreshBarData = (barData: ICNDCertificateExpirationQtdByMonth[]|undefined) => {
    const graphicDataFormat: any[] = [];
    graphicDataFormat.push(['', '']);

    barData?.forEach((val) => graphicDataFormat.push([val.monthYear, val.certificatesExpirationQtd]));

    return graphicDataFormat;
  }

  const showGraphs = () => {
    let showGraph = false;
    let showBar = false;
    const chart = refreshChartData(
      props.cndMacroData?.certificatesQtd,
      props.cndMacroData?.certificatesValidQtd,
      props.cndMacroData?.certificatesErrorQtd,
      props.cndMacroData?.certificatesNoRegistryQtd
    );
    chart.forEach(item => {
      if (+item[1] > 0) showGraph = true;
    })

    const bar = refreshBarData(props.cndMacroData?.certificateExpirationQtdByMonth);
    if (bar) {
      showBar = true;
    }

    const getNoCertificatesMessage = () => {
      let entityType: string;

      switch (props.visualizationType) {
        case 'persona':
          entityType = 'pessoa(s) física(s)';
          break;
        case 'company':
          entityType = 'empresa(s)';
          break;
        default:
          entityType = 'empresa(s)/pessoa(s)';
      }

      return (
          <Text fontSize="18px" fontFamily="Poppins-Medium">
            {`Não há nenhuma certidão emitida para ${entityType}.`}
          </Text>
      );
    };

    const handleChartClick = (chartWrapper: any) => {
      const chart = chartWrapper.getChart();
      const selection = chart.getSelection();
    
      if (selection.length > 0) {
        const selectedItem = selection[0];
        const rowIndex = selectedItem.row;
        
        switch (rowIndex) {
          case 0:
            props.setGraphStatus('valid');
            break;
          case 1:
            props.setGraphStatus('invalid');
            break;
          case 2:
            props.setGraphStatus('error');
            break;
          case 3:
            props.setGraphStatus('no-registry');
            break;
          default:
            props.setGraphStatus(null);
        }
      }
    };

    return (
      <Flex flexDirection="row" gap={2}>
        <Flex className="individual-chart-component" flexDirection="column" backgroundColor="white" borderRadius={5} flexGrow={1} p={4} width="50%" boxShadow="1px 1px 5px #b7b4b4">
          <Text fontSize="18px" fontFamily="Poppins-Medium">
            Situação
          </Text>
          {props.isLoading ? (
            <Flex flexDirection="column" alignItems="center" borderRadius={5} flexGrow={1} style={{height: '232px'}}>
              <RoundShape color="#e0e0e0" style={{width: 200, height: 200}}/>
            </Flex>
          ) : showGraph && chart ?
            (
              <ReactPlaceholder showLoadingAnimation type='rect' ready={!props.isLoading}>
                <Flex flexDirection="column" alignItems="center" borderRadius={5} flexGrow={1}>
                  <Chart
                    chartType="PieChart"
                    data={chart}
                    options={{ 
                      chartArea: { width: "85%", height: "85%" },
                      legend: { position: "right", textStyle: { fontSize: 11 } },
                      colors: ["#DCFCE7", "#FEE2E2", "#FEF9C3", "#E2E8F0"] ,
                      pieSliceTextStyle: { color: "black", fontSize: 11 },
                      fontName: 'Poppins-Medium',
                    }}
                    chartEvents={[
                      {
                        eventName: "select",
                        callback:({ chartWrapper }) => handleChartClick(chartWrapper),
                      },
                    ]} 
                  />
                </Flex>
              </ReactPlaceholder>
            ) : (
              <Flex flexDirection="column" alignItems="center" borderRadius={5} flexGrow={1} mt={5}>
                {getNoCertificatesMessage()}
              </Flex>
            )
          }
        </Flex>
        <Flex className="individual-chart-component" flexDirection="column" backgroundColor="white" borderRadius={5} flexGrow={1} p={4} width="50%" boxShadow="1px 1px 5px #b7b4b4">
          <Text fontSize="18px" fontFamily="Poppins-Medium">
            Certidões à vencer
          </Text>
          {props.isLoading ? (
            <Flex flexDirection="column" alignItems="center" borderRadius={5} flexGrow={1} style={{height: '232px'}}>
              <RectShape color='#E0E0E0' style={{ width: 400, height: 200, marginBottom:'5px' }} />
            </Flex>
          )
            : showBar ?
            <Flex flexDirection="column" alignItems="center" borderRadius={5} flexGrow={1} mt={8}>
              <Chart chartType="Bar" data={bar} options={{ chartArea: { width: "1%", height: "40%" }, legend: { position: 'none' }, colors: ["#4B4EFF", "#ce2b2b"] }} />
            </Flex>
            :
            <Flex flexDirection="column" alignItems="center" borderRadius={5} flexGrow={1} mt={5}>
              <Text fontSize="18px" fontFamily="Poppins-Medium">
                Não há certidões à vencer nos próximos 6 meses
              </Text>
            </Flex>}
        </Flex>
      </Flex>
    )
  }

  if (props.cndMacroData) {
    return (
      <Flex className="custom-chart" flexDirection="column" gap={4}>
        <Flex flexDirection="row" gap={2} mt={4}> 
          <Flex display="none" flexDirection="row" backgroundColor="#FFFFFF"  align="center" flexGrow={2} p={2} border="1px" borderColor="gray.200" borderRadius="6px" boxShadow="1px 1px 1px #b7b4b4">
            <Flex p={1}>
              <img  style={{ maxWidth: '28px' }} alt="" src="/icons/time.png"></img>
            </Flex>
            <Flex flexDirection="column" width="86%" style={{ paddingLeft: '15px' }}>
              <Text marginTop="8px"  fontSize="24px" fontFamily="Poppins-Bold" textAlign="left" >{props.timeSaving}</Text>
              <Text fontSize="14px" color="#0ac337" fontFamily="Poppins-Medium">
                Tempo economizado este mês
              </Text>
            </Flex>
          </Flex>

          <Flex flexDirection="row" backgroundColor="#FFFFFF"  align="center" flexGrow={2} p={2} border="1px" borderColor="gray.200" borderRadius="6px" boxShadow="1px 1px 1px #b7b4b4">
            <Flex p={1}>
              <img alt="" style={{ maxWidth: '28px' }} src="/icons/building2.png"></img>
            </Flex>
            <Flex flexDirection="column" width="86%" style={{ paddingLeft: '15px' }}>
              { 
                props.isLoading ? (
                  <RectShape color='#E0E0E0' style={{ width: 25, height: 25, marginBottom:'5px' }} />
                ) : (
                  <Text 
                    marginTop="8px"
                    fontSize="24px"
                    fontFamily="Poppins-Bold"
                    textAlign="left"
                  >
                    {props.cndMacroData.companiesPersonasQtd}
                  </Text>
                )
              }

              <ReactPlaceholder showLoadingAnimation  type='text' ready={!props.isLoading} rows={1}>
                <Text 
                  fontSize="14px" 
                  color="#636363" 
                  fontFamily="Poppins-Medium"
                >
                  {
                    (props.cndMacroData.companiesPersonasType === 'all')
                    ? 'Empresas/Pessoas monitoradas/Pessoas' : ''
                  }
                  {
                    (props.cndMacroData.companiesPersonasType === 'company')
                    ? 'Empresas monitoradas' : ''
                  }
                  {
                    (props.cndMacroData.companiesPersonasType === 'persona')
                    ? 'Quantidade de Pessoas Físicas' : ''
                  }
                </Text>
              </ReactPlaceholder>
            </Flex>
          </Flex>

          <Flex flexDirection="row" backgroundColor="#FFFFFF"  align="center" flexGrow={2} p={2} border="1px" borderColor="gray.200" borderRadius="6px" boxShadow="1px 1px 1px #b7b4b4">
            <Flex p={1}>
              <img alt="" style={{ maxWidth: '28px' }} src="/icons/building2.png"></img>
            </Flex>
            
            <Flex flexDirection="column" width="86%" style={{ paddingLeft: '15px' }}>
              { 
                props.isLoading ? (
                  <RectShape color='#E0E0E0' style={{ width: 25, height: 25, marginBottom:'5px' }} />
                ) : (
                  <Text 
                    marginTop="8px"
                    fontSize="24px"
                    fontFamily="Poppins-Bold"
                    textAlign="left"
                  >
                    {props.cndMacroData.certificatesQtd}
                  </Text>
                )
              }

              <ReactPlaceholder showLoadingAnimation  type='text' ready={!props.isLoading} rows={1}>
                <Text fontSize="14px" color="#636363" fontFamily="Poppins-Medium">
                  Certidões Encontradas
                </Text>
              </ReactPlaceholder>
            </Flex>
          </Flex>

          <Flex flexDirection="row" backgroundColor="#FFFFFF" align="center" flexGrow={2} p={2} border="1px" borderColor="gray.200" borderRadius="6px" boxShadow="1px 1px 2px #b7b4b4">
            <Flex p={1}>
              <img alt="" style={{ maxWidth: '28px' }} src="/icons/alert.png"></img>
            </Flex> 
            <Flex flexDirection="column" width="86%" style={{ paddingLeft: '15px' }}>
              { 
                props.isLoading ? (
                  <RectShape color='#E0E0E0' style={{ width: 25, height: 25, marginBottom:'5px' }} />
                ) : (
                  <Text 
                    marginTop="8px"
                    fontSize="24px"
                    fontFamily="Poppins-Bold"
                    textAlign="left"
                  >
                    {props.cndMacroData.certificatesExpirationQtd}
                  </Text>
                )
              }
              
              <ReactPlaceholder showLoadingAnimation  type='text' ready={!props.isLoading} rows={1}>
                <Text fontSize="14px" color="#636363" fontFamily="Poppins-Medium">
                  Vencimentos ({moment.utc().locale('pt').format("MMMM")})
                </Text>
              </ReactPlaceholder>
            </Flex>
          </Flex>

          <Flex flexDirection="row" justifyContent="space-between" align="center" backgroundColor="rgba(78,78,255,0.9)"  flexGrow={2} p={2} border="1px" borderColor="gray.200" borderRadius="6px" boxShadow="1px 1px 2px #b7b4b4">
            <Flex flexDirection="column">
              { 
                props.isLoading ? (
                  <RectShape color='#E0E0E0' style={{ width: 144, height: 36, marginBottom:'5px' }} />
                ) : (
                  <Text 
                    marginTop="8px"
                    color="white"
                    fontSize="24px"
                    fontFamily="Poppins-Bold"
                    textAlign="left"
                  >
                    {props.cndMacroData.lastUpdateAt}
                  </Text>
                )
              }
              <ReactPlaceholder showLoadingAnimation  type='text' style={{ width: '134px' }} ready={!props.isLoading} rows={1}>
                <Text fontSize="14px" color="white" fontFamily="Poppins-Medium">
                  Última atualização
                </Text>
              </ReactPlaceholder>
            </Flex>
            <Flex flexDirection="column">
              { 
                props.isLoading ? (
                  <RectShape color='#E0E0E0' style={{ width: 144, height: 36, marginBottom:'5px' }} />
                ) : (
                  <Text 
                    marginTop="8px"
                    color="white"
                    fontSize="24px"
                    fontFamily="Poppins-Bold"
                    textAlign="left"
                  >
                    {props.cndMacroData.nextUpdateAt}
                  </Text>
                )
              }
              <ReactPlaceholder showLoadingAnimation  type='text' style={{ width: '134px' }} ready={!props.isLoading} rows={1}>
                <Text fontSize="14px" color="white" textAlign="right" fontFamily="Poppins-Medium">
                  Próxima atualização
                </Text>
              </ReactPlaceholder>
            </Flex>
          </Flex>
        </Flex>
        
        {showGraphs()}
      </Flex>
    );
  }
  return (
    <Flex className="custom-chart" flexDirection="column" gap={4}>
      Algo deu errado, por favor entre em contato com o suporte.
    </Flex>
  );
}