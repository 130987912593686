import { ApiAuth, ApiCall } from "./api";
import { TPremiseResponse } from "./interface/IDefaults";
import { ICityResponse, ICountry, IRegionResponse } from "./interface/region.interface";

export const generate2fa = async (guid_user: string): Promise<TPremiseResponse<void>> => {
  return await new ApiCall(`/two-factor-authentication/generate/${guid_user}`, ApiAuth()).get<void>();
}

export const validate2fa = async (guid_user: string, code: string): Promise<TPremiseResponse<boolean>> => {
  return await new ApiCall(`/two-factor-authentication/validate/${guid_user}/${code}`, ApiAuth()).get<boolean>();
}