import { CircularProgress, CircularProgressLabel, Flex, Text, useDisclosure } from "@chakra-ui/react";
import { ICNDListData, IFilterListCertificate } from "../../../../_services/interface/cnd.interface"
import { IWidgetIdent } from "../interfaces/IWidget";
import { calcPercent } from "../util/util";
import { WidgetBase } from "./WidgetBase"
import moment from 'moment';
import { ICertificate } from "../../../../_services/interface/certificate.interface";
import { getCertificatesPropertyValuesRecursively } from "../../Certificate/Util/CertificateUtils";
import { IClientDataResponse } from "../../../../_services/interface/client.interface";
import { RoundShape } from "react-placeholder/lib/placeholders";
import { useState } from "react";
import { ViewCertificatesModal } from "../../CNDManager/modals/ViewCertificatesModal";
moment.updateLocale('pt', {
  months: [
    "Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho",
    "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"
  ]
});

interface ICertPie extends IWidgetIdent {
  data?: ICNDListData[];
  isLoading?: boolean;
  currentClient?: IClientDataResponse;
  certificates?: ICertificate[];
  startDateFilter?: string;
  endDateFilter?: string;
  filter?: IFilterListCertificate;
}

interface ICndComplianceWidget {
  name: string;
  percent: number;
  slug: string;
  certificates: ICertificate[];
  currentClient?: IClientDataResponse;
  startDateFilter?: string;
  endDateFilter?: string;
  data?: ICNDListData[];
  filter?: IFilterListCertificate;
}

export const CndComplianceWidget = (props: ICndComplianceWidget) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [ dataTypeToModal, setDataTypeToModal ] = useState<'general'|null>(null);
  const [ title, setTitle ] = useState<string>('');

  const open = () => {
    onOpen();
    setDataTypeToModal('general');
  }
  
  const closeModal = () => {
    onClose();
    setDataTypeToModal(null);
    setTitle('');
  }

  const getFilter = () => {
    if (props.slug) {
      const filter: IFilterListCertificate = {
        page: props.filter ? props.filter.page : 1,
        row_by_page: props.filter ? props.filter.row_by_page : -1,
        ...props.filter,
        slugs: props.slug,
      };

      return filter;
    }

    return props.filter;
  };

  return (
    <>
      <Flex flexDirection="column" cursor="pointer" onClick={open} justifyContent="center" gap={4}>
        <Text textAlign="center">{props.name}</Text>
        <CircularProgress value={props.percent} color="#4b4eff" size='150px' thickness='10px'>
          <CircularProgressLabel fontSize={14} fontWeight="bold">
            {props.percent}%<br/>Emitido
          </CircularProgressLabel>
        </CircularProgress>
      </Flex> 

      {props.certificates && props.currentClient && (
        <ViewCertificatesModal
          guid_client={props.currentClient.guid_client}
          title={title}
          isOpen={isOpen}
          onClose={closeModal}
          filter={getFilter()}
          type={dataTypeToModal}
        />
      )}
    </>
  )
}

export const CertPie = (props: ICertPie) => {  
  const cndData = props.data ? props.data as ICNDListData[] : [];
  if (props.id && props.index !== undefined && props.currentClient) {
    const products = props.currentClient.products;
    return (
      <WidgetBase title={`Compliance por tipo de consulta`} id={props.id} index={props.index}>
        <Flex flexDirection="column" gap={4} width="100%" alignItems="center" justifyContent="center">
          <Flex flexWrap="wrap" display="flex" flexDirection="row" gap={4} width="100%" alignItems="center" justifyContent="center">
            {
              !props.isLoading ? (
                props.certificates?.map((x) => {

                  const availableSlug = getCertificatesPropertyValuesRecursively([x] as ICertificate[], 'slug' as keyof ICertificate);

                  const count = cndData.filter((y) => availableSlug.some((slug) => slug.includes(y.slug)) && ['not-pendency', 'negative-positive'].includes(y.slug_status)).length;

                  let hasProduct = products[x.slug as string];
                  if(!hasProduct && x.children) {
                    hasProduct = x.children.some((child) => products[child.slug as string]);
                  }

                  return hasProduct && props.certificates && props.currentClient && (
                    <>
                      <CndComplianceWidget 
                        name={x.name as string} 
                        slug={x.slug as string} 
                        percent={calcPercent(count,cndData.filter((y) => availableSlug[0].includes(y.slug)).length)}
                        certificates={props.certificates}
                        currentClient={props.currentClient}
                        startDateFilter={props.startDateFilter}
                        endDateFilter={props.endDateFilter}
                        data={props.data}
                        filter={props.filter}
                      />
                    </>
                  )
                })
              ) : (<>
                <RoundShape color="#e0e0e0" style={{width: 130, height: 130}}/>
                <RoundShape color="#e0e0e0" style={{width: 130, height: 130}}/>
                <RoundShape color="#e0e0e0" style={{width: 130, height: 130}}/>
              </>)
            }
          </Flex>
        </Flex>
        
      </WidgetBase>
    )
  } else {
    return (
      <WidgetBase title={`Compliance por tipo de consulta`} id="loading_cert_pie" index={0}>
        <Flex flexDirection="column" gap={4} width="100%" alignItems="center" justifyContent="center">
          <Flex flexWrap="wrap" display="flex" flexDirection="row" gap={4} width="100%" alignItems="center" justifyContent="center">
            {
              Array.from({ length: 10 }, (_, index) => (
                (<RoundShape color="#e0e0e0" style={{width: 130, height: 130}}/>)
              ))
            }
          </Flex>
        </Flex>
        
      </WidgetBase>
    )
  }
}