import { Flex, Text, useDisclosure, useToast } from "@chakra-ui/react";
import Chart from "react-google-charts";
import { ICertificateExpiredGraph, ICertificateRenewedGraph, IFilterListCertificate } from "../../../../_services/interface/cnd.interface";
import { IWidgetIdent } from "../interfaces/IWidget";
import { WidgetBase } from "./WidgetBase";
import { RectShape } from "react-placeholder/lib/placeholders";
import { IClientDataResponse } from "../../../../_services/interface/client.interface";
import { ViewCertificatesModal } from "../../CNDManager/modals/ViewCertificatesModal";
import { useRef, useState } from "react";
import { exportCertificateGraph } from "../../../../_services/cnd.service";
import { downloadFile } from "../../../../utils/utils";

interface ICNDExpired extends IWidgetIdent {
  data?: ICertificateExpiredGraph|ICertificateRenewedGraph;
  isLoading?: boolean;
  startCreatedAtFilter?: string;
  endCreatedAtFilter?: string;
  currentClient?: IClientDataResponse;
  filter?: IFilterListCertificate
  typeCertificateGraph?: 'expired'|'renewed';
}

export const CNDExpired = (props: ICNDExpired) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const toastRef = useRef<string | number | undefined>(undefined);
  const [title, setTitle] = useState<string>("");
  const [dataTypeToModal, setDataTypeToModal] = useState<'vigentes'|'vencer'|'vencidas'|'renovadasAuto'|'renovadasManual'|'renovadasTotal'|null>(null);

  const vigentes = props.data ? (props.data as ICertificateExpiredGraph).vigentes ?? 0 : 0;
  const vencer = props.data ? (props.data as ICertificateExpiredGraph).vencer ?? 0 : 0;
  const vencidas = props.data ? (props.data as ICertificateExpiredGraph).vencidas ?? 0 : 0;

  const totalExpired = vigentes + vencer + vencidas; // Calcula o total de certidões
  const vigentesPercentage = totalExpired > 0 ? ((vigentes / totalExpired) * 100).toFixed(1) : 0;
  const vencerPercentage = totalExpired > 0 ? ((vencer / totalExpired) * 100).toFixed(1) : 0;
  const vencidasPercentage = totalExpired > 0 ? ((vencidas / totalExpired) * 100).toFixed(1) : 0;
  
  
  const renovadasAuto = props.data ? (props.data as ICertificateRenewedGraph).renovadasAuto ?? 0 : 0;
  const renovadasManual = props.data ? (props.data as ICertificateRenewedGraph).renovadasManual ?? 0 : 0;
  const renovadasTotal = renovadasAuto + renovadasManual;

  const renovadasAutoPercentage = renovadasTotal > 0 ? ((renovadasAuto / renovadasTotal) * 100).toFixed(1) : 0;
  const renovadasManualPercentage = renovadasTotal > 0 ? ((renovadasManual / renovadasTotal) * 100).toFixed(1) : 0;

  const closeModal = () => {
    onClose();
    setDataTypeToModal(null);
  }

  const refreshBarData = () => {
    let finalData: any[] = props.typeCertificateGraph === 'expired' ? [
      ["Categoria", "Quantidade", { role: "style" }, {role: "annotation",type: "string",}],
      ["Total", (vencidas+vigentes+vencer), "#93c7f8", (vencidas+vigentes+vencer).toString()],
      ["Certidões vigentes", vigentes, "#dcfce7", `${vigentes} (${vigentesPercentage}%)`],
      ["Certidões a vencer", vencer, "#fef9ce", `${vencer} (${vencerPercentage}%)`],
      ["Certidões vencidas", vencidas, "#f89393", `${vencidas} (${vencidasPercentage}%)`],
    ] : [
      ["Categoria", "Quantidade", { role: "style" }, {role: "annotation",type: "string",}],
      ["Total", renovadasTotal, "#4b4eff", renovadasTotal.toString()],
      ["Automáticas", renovadasAuto, "#a7a9f5",`${renovadasAuto} (${renovadasAutoPercentage}%)`],
      ["Manuais", renovadasManual, "#cecece", `${renovadasManual} (${renovadasManualPercentage}%)`],
    ];

    if (props.currentClient?.clientConfig?.ignore_config && props.typeCertificateGraph === 'expired') {
      finalData = finalData.filter(item => item[0] !== "Certidões a vencer");
    }

    return finalData;
  }

  let showBar = false;
  const bar = refreshBarData()
  bar.forEach(item => {
    if (item[1] > 0) showBar = true;
  })

  const handleBarClick = (chartWrapper: any) => {
    const chart = chartWrapper.getChart();
    const selection = chart.getSelection();
  
    if (selection.length > 0) {
      const selectedItem = selection[0];
      const category = bar[selectedItem.row + 1][0];
  
      setTitle(category);
  
      // Usa o valor atualizado de cada estado
      switch (category) {
        case 'Certidões vigentes':
          setDataTypeToModal('vigentes');
          break;
        case 'Certidões a vencer':
          setDataTypeToModal('vencer');
          break;
        case 'Certidões vencidas':
          setDataTypeToModal('vencidas');
          break;
        case 'Automáticas':
          setDataTypeToModal('renovadasAuto');
          break;
        case 'Manuais':
          setDataTypeToModal('renovadasManual');
          break;
        case 'Total':
          setDataTypeToModal('renovadasTotal');
          break;
        default:
          setDataTypeToModal(null);
      }
  
      onOpen();
    };
  };

  if (props.id && props.index !== undefined && props.currentClient && props.currentClient.clientConfig) {

    const exportReport = async () => {
      if (props.currentClient?.guid_client && props.filter && props.typeCertificateGraph) {
        toastRef.current = toast({
          title: "Baixando relatório",
          description: `Isso pode demorar alguns instantes, por favor aguarde.`,
          status: "info",
          duration: null,
          isClosable: false,
        });

        const { response, status } = await exportCertificateGraph(props.currentClient.guid_client, props?.filter, props.typeCertificateGraph === 'expired' ? 'expired' : 'renewed');

        if (status && status === 200 && 'xlsxUrl' in response) {
          const fileName = `Relatório Certidões - ${props.currentClient.name}.xlsx`;
          await downloadFile(response.xlsxUrl, fileName);
        }

        toastRef.current && toast.close(toastRef.current);
      }
    }

    const showGraphs = () => {

      return (
        <Flex alignItems="center" justifyContent="center" position="relative" gap={2} w="100%">
          <Flex flexDirection="column" w="100%" gap={4}>
            <Text fontWeight="bold" textAlign="center" color="gray.500">Certidões</Text>
            {
              props.isLoading ? (
                <RectShape color='#E0E0E0' style={{ width: 413, height: 200, marginBottom:'5px' }} />
              ) : showBar ? 
                <Chart 
                  chartType="BarChart" 
                  data={bar} 
                  legendToggle
                  options={
                    {
                      fontName: 'Poppins-Medium',
                      chartArea: {
                        width: "50%", 
                        height: "80%" 
                      }, 
                      legend: {
                        position: 'none'
                      },
                      annotations: { alwaysOutside: true, textStyle: { fontSize: 12, color: '#000' } },
                    }
                  } 
                  chartEvents={[
                    {
                      eventName: "select",
                      callback:({ chartWrapper }) => handleBarClick(chartWrapper),
                    },
                  ]}
                />
              : "Não há certidões para esse período"
            }
          </Flex>
        </Flex>
      )
    }

    return (<>
      <WidgetBase 
        title={props.typeCertificateGraph === 'expired' ? "Status de vigência das certidões" : "Certidões monitoradas"} 
        id={props.id} 
        index={props.index}
        exportFunction={exportReport}
      >
        {showGraphs()}
      </WidgetBase>

      <ViewCertificatesModal
        guid_client={props.currentClient.guid_client}
        title={title}
        isOpen={isOpen}
        onClose={closeModal}
        filter={props.filter}
        type={dataTypeToModal}
      />
    </>);
  } else {
    return null;
  }
}
