import { ICardNameFilter } from "../components/Interfaces/Filter.interface";
import { ApiAuth, ApiCall } from "./api";
import { ICompanyFilter, ITaxCurrentAccountFilter, IPersonaFilter, IDigitalCertificateFilter } from "./interface/filter.interface";
import { TPremiseResponse } from "./interface/IDefaults";

export const getFilterCardName = async (guid_client: string): Promise<TPremiseResponse<any>> => {
  const url = `/filter/${guid_client}/card/0`;
  return await new ApiCall(url, ApiAuth()).get<ICardNameFilter[]>();
};

export const getCompanyFilterOptions = async (guid_client: string, all: boolean = false): Promise<TPremiseResponse<ICompanyFilter[]>> => {
  return await new ApiCall(`/filter/${guid_client}/company/${all ? '1' : '0'}`, ApiAuth()).get<ICompanyFilter[]>();
};

export const getPersonaFilterOptions = async (guid_client: string): Promise<TPremiseResponse<IPersonaFilter[]>> => {
  return await new ApiCall(`/filter/${guid_client}/persona/0`, ApiAuth()).get<IPersonaFilter[]>();
};

export const getTaxCurrentAccountFilterOptions = async (
  guid_client: string
): Promise<TPremiseResponse<ITaxCurrentAccountFilter>> => {
  return await new ApiCall(`/filter/${guid_client}/tax-current-account/0`, ApiAuth()).get<ITaxCurrentAccountFilter>();
};

export const getDigitalCertificateFilterOptions = async (
  guid_client: string
): Promise<TPremiseResponse<IDigitalCertificateFilter>> => {
  return await new ApiCall(`/filter/${guid_client}/digital-certificate/0`, ApiAuth()).get<IDigitalCertificateFilter>();
};