import { AddIcon, ArrowDownIcon, ArrowUpDownIcon, ArrowUpIcon } from "@chakra-ui/icons";
import { Flex, Modal, ModalOverlay, ModalContent, Button, ButtonGroup, ModalHeader, ModalCloseButton, ModalBody, useDisclosure, Text, Checkbox, Table, TableContainer, Th, Thead, Tr, Tbody, Td, Tooltip, Image, ModalFooter } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FormButton } from "../components/login/button.component";
import { formatCnpj } from "../pages/dashboard/Companies/Company/util/cnpj";
import { IClientCompanies } from "../_services/interface/company.interface";
import { RetrieveCertificatesStyle } from "../styles/components/RetrieveCertificates";
import { UserTag } from "../pages/dashboard/Users/UserPanel";
import { FilterDataSelected, FilterRow, FilterTypeEnum, Filters } from "../components/Filters";
import { IColumn, ITableMetaData, IData, IDataMeta } from "../components/Table/table.interface";
import { stateRegionForTag } from "../components/Table/utils";
import { Datatables } from "../components/Table/Datatables";

interface ISelectCompaniesProps {
  guid_client: string;
  companies: IClientCompanies[];
  isLoading: boolean;
  showInText?: boolean;
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  open?: boolean;
  setSelectedCompanies: React.Dispatch<React.SetStateAction<string[]>>;
  selectedCompanies: string[];
  header: string;
  description: string;
  buttonOpenModalText?: string;
  buttonText: string;
  callback?: () => void;
  allCompanies?: boolean;
  disabled?: boolean;
}

export const SelectCompanies = (props: ISelectCompaniesProps) => {
  const [filterDataSelected, setFilterDataSelected] = useState<FilterDataSelected|null>(null);
  
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [filteredCompanies, setFilteredCompanies] = useState<IData<IClientCompanies>|null>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [companyChecked, setCompanyChecked] = useState<string[]>([]);
  const [companyCheckedList, setCompanyCheckedList] = useState<IData<any>|null>(null);
  const [companyCheckedListMetaData, setCompanyCheckedListMetaData] = useState<ITableMetaData|undefined>();
  const [companyMetaData, setCompanyMetaData] = useState<ITableMetaData|undefined>();
  const [showOnlySelecteds, setShowOnlySelecteds] = useState<boolean>(false);

  useEffect(() => {
    if (props.open) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [props.open]);

  useEffect(() => {
    if (open) {
      onOpen();
      if(props.setOpen) {
        props.setOpen(true)
      }
      setCompanyCheckedListMetaData(undefined);
      setShowOnlySelecteds(false);
    } else {
      onClose();
      if(props.setOpen) {
        props.setOpen(false)
      }
    }
  }, [open]);

  useEffect(() => {
    setCompanyChecked(props.selectedCompanies);
  }, [props.selectedCompanies]);

  useEffect(() => {
    if (companyChecked && filterDataSelected) {
      const filteredCompanies = filterCompanies(props.companies.filter((company) => companyChecked.includes(company.guid_company)), filterDataSelected);
      const totalPages: number = Math.round(filteredCompanies.length/10);
      
      let meta: IDataMeta = {
        currentPage: 1,
        isFirstPage: true,
        isLastPage: totalPages === 1,
        nextPage: totalPages > 1 ? 2 : null,
        pageCount: totalPages,
        previousPage: null,
        totalCount: filteredCompanies.length,
      };

      let start = 0;
      let end = start + 10;

      const orderedCompanies = sortCompanies(filteredCompanies, companyCheckedListMetaData);

      if (companyCheckedListMetaData) {
        const { currentPage, totalPages, rowByPage } = companyCheckedListMetaData;
        
        start = (currentPage - 1) * rowByPage;
        end = start + rowByPage;
  
        meta = {
          currentPage,
          isFirstPage: currentPage === 1,
          isLastPage: currentPage === totalPages,
          nextPage: currentPage === totalPages ? null : currentPage + 1,
          pageCount: Math.ceil(orderedCompanies.length / rowByPage),
          previousPage: currentPage === 1 ? null : currentPage - 1,
          totalCount: orderedCompanies.length
        }
      }

      const paginatedData = orderedCompanies.slice(start, end);

      setCompanyCheckedList({
        data: paginatedData,
        meta
      });
    }
  }, [companyChecked, companyCheckedListMetaData, filterDataSelected, props.companies]);

  useEffect(() => {
    if (companyMetaData && props.companies && filterDataSelected) {
      const filteredCompanies = filterCompanies(props.companies, filterDataSelected);
      const orderedCompanies = sortCompanies(filteredCompanies, companyMetaData);

      const { currentPage, totalPages, rowByPage } = companyMetaData;
      
      const data = orderedCompanies;
      const start = (currentPage - 1) * rowByPage;
      const end = start + rowByPage;
      const paginatedData = data.slice(start, end);

      const meta: IDataMeta = {
        currentPage,
        isFirstPage: currentPage === 1,
        isLastPage: currentPage === totalPages,
        nextPage: currentPage === totalPages ? null : currentPage + 1,
        pageCount: Math.ceil(orderedCompanies.length / rowByPage),
        previousPage: currentPage === 1 ? null : currentPage - 1,
        totalCount: orderedCompanies.length
      }

      setFilteredCompanies({
        data: paginatedData,
        meta
      });
    }
  }, [companyMetaData, props.companies, filterDataSelected]);

  const sortCompanies = (
    companies: IClientCompanies[],
    companyMetaData?: ITableMetaData, 
  ): IClientCompanies[] => {
    const companiesSort = companies;
  
    const orderField = companyMetaData?.orderField as keyof IClientCompanies;
    const orderDirection = companyMetaData?.orderDirection;
  
    const compareFunction = (a: IClientCompanies, b: IClientCompanies): number => {
      const aValue = a[orderField];
      const bValue = b[orderField];
  
      if (aValue === undefined || bValue === undefined) {
        return 0;
      }

      if (typeof aValue === 'string' && typeof bValue === 'string') {
        const comparison = aValue.localeCompare(bValue);
        return orderDirection === 'asc' ? comparison : -comparison;
      }
  
      if (aValue < bValue) {
        return orderDirection === 'asc' ? -1 : 1;
      }
      if (aValue > bValue) {
        return orderDirection === 'asc' ? 1 : -1;
      }
      return 0;
    };
  
    companiesSort.sort(compareFunction);
  
    return companiesSort;
  };

  const filterCompanies = (orderedCompanies: IClientCompanies[], filterDataSelected: FilterDataSelected): IClientCompanies[] => {
    let companies = orderedCompanies;

    if (orderedCompanies.length > 0) {
      if(filterDataSelected[FilterTypeEnum.companyCityFilter].length > 0) {
        companies = companies.filter((company) => filterDataSelected[FilterTypeEnum.companyCityFilter].includes(company.city));
      }

      if(filterDataSelected[FilterTypeEnum.companyCodeFilter].length > 0) {
        companies = companies.filter((company) => filterDataSelected[FilterTypeEnum.companyCodeFilter].includes(company.companyCode));
      }

      if(filterDataSelected[FilterTypeEnum.companyTypeFilter].length > 0) {
        companies = companies.filter((company) => filterDataSelected[FilterTypeEnum.companyTypeFilter].includes(company.type === 'holding' ? '0' : '1'));
      }

      if(filterDataSelected[FilterTypeEnum.companyStateFilter].length > 0) {
        companies = companies.filter((company) => filterDataSelected[FilterTypeEnum.companyStateFilter].includes(company.initials));
      }

      if(filterDataSelected[FilterTypeEnum.companyFilter].length > 0) {
        companies = companies.filter((company) => filterDataSelected[FilterTypeEnum.companyFilter].includes(company.guid_company));
      }

      if(filterDataSelected[FilterTypeEnum.companyGroupFilter].length > 0) {
        companies = companies.filter((company) => company.groups.some((r) => filterDataSelected[FilterTypeEnum.companyGroupFilter].includes(r.guid_company_group)));
      }

      return companies;
    }

    return [];
  }

  const selectAll = () => {
    if(filterDataSelected) {
      const filteredCompanies = filterCompanies(props.companies, filterDataSelected);

      if (companyChecked.length === filteredCompanies.filter((company) => company.isActive).length && companyChecked.length > 0) {
        setCompanyChecked([]);
      } else {
        setCompanyChecked(filteredCompanies.filter((company) => company.isActive).map((x): string => x.guid_company));
      }
    }
  }

  const submitForm = () => {
    props.setSelectedCompanies(companyChecked);
    setOpen(false);

    if (props.callback) {
      props.callback();
    }
  }

  const filterRows = [
    {
      rowFields: [
        {
          name: FilterTypeEnum.companyFilter,
          position: 1,
          size: 6,
        },
        {
          name: FilterTypeEnum.companyGroupFilter,
          position: 2,
          size: 3,
        },
        {
          name: FilterTypeEnum.companyTypeFilter,
          position: 3,
          size: 3,
        },
      ],
      position: 1
    },
    {
      rowFields: [
        {
          name: FilterTypeEnum.companyCodeFilter,
          position: 1,
          size: 3,
        },
        {
          name: FilterTypeEnum.companyStateFilter,
          position: 2,
          size: 3,
        },
        {
          name: FilterTypeEnum.companyCityFilter,
          position: 3,
          size: 6,
        }
      ],
      position: 2
    }
  ] as FilterRow[];

  const columns = [
    {
      title: 'Tipo',
      name: 'type',
      sortable: true,
      align: 'center',
      tags: [
        {
          text: 'Matriz',
          value: 'holding',
          color: '#ECFCCB',
          textColor: '#365314'
        },
        {
          text: 'Filial',
          value: 'subsidiary',
          color: '#C4F1F9',
          textColor: '#065666'
        },
        { 
          text: 'Estrangeira',
          value: 'foreign',
          color: '#CCFBF1',
          textColor: '#134E4A',
        }
      ],
    },
    {
      title: 'CNPJ',
      name: 'cnpj',
      formatter: formatCnpj,
      sortable: true,
      align: 'center',
    },
    {
      title: 'Nome - (Cód)',
      name: 'nameWithCode',
      orderFieldName: 'nameWithCode',
      sortable: true,
      align: 'center',
    },
    {
      title: 'Estado',
      name: 'initials',
      sortable: true,
      align: 'center',
      dynamicTag: {
        function: stateRegionForTag,
        field: 'initials'
      },
    },
    {
      title: 'Cidade',
      name: 'city',
      sortable: true,
      align: 'center',
      tags: [
        { 
          color: '#E2E8F0',
          textColor: '#1A202C'
        },
      ]
    }
  ] as IColumn[];

  return (
    <>
      {
        !props.setOpen &&
        (
          
            props.showInText ? (
              <>
                <Text
                  color="#4B4EFF"
                  fontFamily="Poppins-Medium"
                  fontSize="12px"
                  fontStyle="normal"
                  fontWeight="500"
                  lineHeight="normal"
                  onClick={()=> setOpen(true)}
                  cursor="pointer"
                >
                  {companyChecked.length} Empresas
                </Text>
              </>
            ) : (
              <ButtonGroup onClick={()=> setOpen(true)} size="sm" mt="24px" isAttached color="white">
                <Button leftIcon={<AddIcon />} bgColor="#4B4EFF" color="white" _hover={{ bg: '#282be0' }}>
                  {props.buttonOpenModalText}
                </Button>
              </ButtonGroup>
            )
          
        )
      }

      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={() => setOpen(false)} size="7xl" >
        <RetrieveCertificatesStyle>
          <ModalOverlay className="modal-overlay" />
          <ModalContent width={1487} className="modal-content" >
            <ModalHeader className="header">
              <Text className="title">{props.header}</Text>
              <Text className="description">
                <div>
                  <Flex style={{marginTop: "16px"}} className="blue-text">{props.description}</Flex>
                </div>
              </Text>
              <Flex
                style={{marginTop: "12px"}}
              >
                <Filters
                  filters={filterRows}
                  selectedValuesHook={setFilterDataSelected}
                  allCompanies={props.allCompanies}
                />
              </Flex>
            </ModalHeader>

            <ModalCloseButton /> 

            <ModalBody fontFamily="Poppins-Medium">
              <hr />
              <Flex position="relative" direction="column">
                <Flex
                  cursor="pointer"
                  position="absolute"
                  mt={8}
                  onClick={() => setShowOnlySelecteds(!showOnlySelecteds)}
                  display={
                    props.companies.length > 1
                    && companyChecked.length > 0
                    ? 'flex' : 'none'}
                >
                  {showOnlySelecteds ? (
                    <Image w="40px" src="/icons/switch-blue.svg" />
                  ) : (
                    <Image w="40px" src="/icons/switch-gray.svg" />
                  )}

                  <Flex direction="column" ml={2}>
                    <Text fontSize="14px">
                        Exibir apenas registros selecionados
                    </Text>
                  </Flex>
                </Flex>

                <Flex alignItems="stretch" direction="column" gap={2} flexGrow={1} mt={8} mb={4} >
                  {
                    showOnlySelecteds ? 
                    (
                      <Datatables<IClientCompanies>
                        name="Empresas"
                        columns={columns}
                        metaDataHook={setCompanyCheckedListMetaData}
                        data={companyCheckedList}
                        isLoading={props.isLoading}
                        showTotalRows={true}
                        heigth="400px"
                      />
                    ) : 
                    (
                      <Datatables<IClientCompanies>
                        name="Empresas"
                        columns={columns}
                        metaDataHook={setCompanyMetaData}
                        data={filteredCompanies}
                        isLoading={props.isLoading}
                        showTotalRows={true}
                        heigth="400px"
                        checkBox={{
                          selecteds: companyChecked,
                          setSelecteds: setCompanyChecked,
                          checkAll: selectAll,
                          key: 'guid_company',
                          disabled: props.disabled
                        }}
                      />
                    )

                  }
                </Flex>
              </Flex>
              
            </ModalBody> 
            <ModalFooter>
              <Flex width="100%" flexDirection="column">
                <Flex width="100%" justifyContent="end" mt="20px" alignItems="flex-start" display="flex">
                  <FormButton onClick={submitForm} width="auto">
                    {props.buttonText}
                  </FormButton> 
                </Flex>
              </Flex>
            </ModalFooter>
          </ModalContent>
        </RetrieveCertificatesStyle>
      </Modal>
    </>
  );
}