import { useEffect, useRef, useState } from "react";
import "../styles/components/selectizeCustom.css";
import 'selectize';
import $ from 'jquery';
import 'selectize';

interface ISelectMultiEmailProps {
    onChange?: (emails: string[]) => void;
    getSelectedEmails?: () => Promise<string[]>;
    getEmailsOptions?: () => Promise<string[]>;
    dropdownParent?: string;
    disabled?: boolean;
}

interface IOptions {
    value: string;
    label: string;
}

export default function SelectMultiEmail(props: ISelectMultiEmailProps) {
    const selectRef = useRef<HTMLSelectElement | null>(null);
    const selectizeRef = useRef<any | null>(null);
    const [selectedEmails, setSelectedEmails] = useState<string[] | undefined>(undefined);
    const [emailsOptions, setEmailsOptions] = useState<string[] | undefined>(undefined);


    const isValidEmail = (email: string) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const initializeSelectize = async () => {
        if (!selectRef.current) return;

        const emailsOptions = await configEmailsOptions();
        const selectedEmails = await configSelectedEmails();

        const options: IOptions[] = emailsOptions?.map(email => ({ value: email, label: email, search: email } as IOptions)) || [];

        const $select = $(selectRef.current).selectize({
            plugins: ['remove_button'],
            delimiter: ',',
            persist: true,
            create: function(input: string) {
                if (isValidEmail(input)) {
                    return { value: input, label: input };
                }
                return false;
            },
            dropdownParent: props.dropdownParent,
            placeholder: 'Digite os emails...',
            valueField: 'value',
            labelField: 'label',
            options,
            searchField: ['label', 'search'],
            onChange: (value: string[]) => {
                if (props.onChange) {
                    props.onChange(value);
                }
            }
        });

        selectizeRef.current = $select[0].selectize;

        if (selectedEmails) {
            selectedEmails.map(email => {
                if (!selectizeRef.current.options[email]) {
                    selectizeRef.current.addOption({ value: email, label: email });
                }
            });

            selectizeRef.current.setValue(selectedEmails);
        }
    }

    const destroySelectize = () => {
        if (selectizeRef.current) {
            selectizeRef.current.destroy();
        }
    }

    const configSelectedEmails = async (): Promise<string[]> => {
        if (props.getSelectedEmails) {
            const selectedEmails = await props.getSelectedEmails();
            setSelectedEmails(selectedEmails);

            return selectedEmails;
        } else {
            setSelectedEmails([]);
        }

        return [];
    }

    const configEmailsOptions = async (): Promise<string[]> => {
        if (props.getEmailsOptions) {
            const emailsOptions = await props.getEmailsOptions();
            setEmailsOptions(emailsOptions);
            return emailsOptions;
        } else {
            setEmailsOptions([]);
        }
        return [];
    }


    useEffect(() => {
        destroySelectize();
        initializeSelectize();
    }, []);

    return <select style={{ width: '100%' }} multiple disabled={props.disabled} ref={selectRef}></select>;
}
