import moment from "moment";
import { Box, Button, Flex, Image, Text, useToast } from "@chakra-ui/react";
import { checkCollaboratorPermission } from "../Users/utils/checkPermission";
import useLoginFormStore from "../../../store/useLoginFormStore";
import { useClientContext } from "../../../components/sidebar";
import { IResponse } from "../../../_services/interface/user.interface";
import { useEffect, useState } from "react";
import { FilterDataSelected, Filters, FilterTypeEnum } from "../../../components/Filters";
import { Datatables } from "../../../components/Table/Datatables";
import { taxCurrentAccountFilter } from "./util/TaxCurrentAccountPanel";
import { IAction, IColumn, IData, ITableMetaData } from "../../../components/Table/table.interface";
import { taxCurrentAccountStatusForTagList } from "./util/TaxCurrentAccountUtils";
import { IFilterListTaxCurrentAccount, ITaxCurrentAccountList, ITaxCurrentAccountListData } from "../../../_services/interface/taxCurrentAccount.interface";
import { listTaxCurrentAccount } from "../../../_services/taxCurrentAccount.service";
import { entityTags, errorMessage, jurisdictionTags, stateRegionForTag, validFilterField } from "../../../components/Table/utils";
import { ViewPdfModal } from "../CNDManager/modals/ViewPdfModal";
import { TaxCurrentAccountHistory } from "./TaxCurrentAccountHistory";
import { TaxCurrentAccountSearchModal } from "./TaxCurrentAccountSearchModal";
import { downloadFile } from "../../../utils/utils";
import { TaxCurrentAccountMultiDownload } from "./modal/TaxCurrentAccountMultiDownload";
import { Socket } from "socket.io-client";
import { DefaultEventsMap } from "@socket.io/component-emitter";
import { CloseIcon } from "@chakra-ui/icons";
import { TaxCurrentAccountAddNewManual } from "./modal/TaxCurrentAccountAddNewManual";

interface ITaxCurrentAccountPanelProps {
  socket: Socket<DefaultEventsMap, DefaultEventsMap> | undefined;
}

export const TaxCurrentAccountPanel = (props: ITaxCurrentAccountPanelProps) => {
  // Global 
  const { validate } = useLoginFormStore();
  const { guid_client } = useClientContext();
  const toast = useToast();
  
  // Filter
  const [filterDataSelected, setFilterDataSelected] = useState<FilterDataSelected|null>(null);
  
  // Component
  const [ isLoading, setLoading ] = useState<boolean>(false);
  const [metaData, setMetaData] = useState<ITableMetaData|undefined>();
  const [refreshData, setRefreshData] = useState<boolean>(false);
  
  // Tax Current Account
  const [taxCurrentAccountList, setTaxCurrentAccountList] = useState<IData<ITaxCurrentAccountListData>|null>(null);
  const [currentTaxCurrentAccount, setCurrentTaxCurrentAccount] = useState<ITaxCurrentAccountListData|null>(null);

  // File Modal
  const [currentFile, setCurrentFile] = useState<string>('');
  const [openFileModal, setOpenFileModal] = useState<boolean>(false);

  // History Modal
  const [openHistoryModal, setOpenHistoryModal] = useState<boolean>(false);

  // Search Tax Current Account Modal
  const [openSearchTaxCurrentAccountModal, setOpenSearchTaxCurrentAccountModal] = useState<boolean>(false);


  useEffect(() => {
    fetchTaxCurrentAccountData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [guid_client, filterDataSelected, metaData, refreshData]);

  const activeWebsocket = () => {
    if(props.socket){
      props.socket.on('refreshTaxCurrentAccount', (message) => {
        if (message.type === 'refreshTaxCurrentAccount') {
          toast.closeAll();

          toast({
            isClosable: true,
            duration: 6000,
            status: 'success',
            render: ({ onClose }) => (
              <>
                <Box color='white' pt={3} pb={3} pl={3} pr={1} bg='green.500' borderRadius={5} height="100%">
                  <Flex fontFamily="Poppins-Medium" justifyContent="space-between" mb={1} display="flex">
                    <Flex display="flex" flexDirection="column">
                      <Image src="/icons/check-white.svg" width="35px" />
                    </Flex>

                    <Flex display="flex" flexDirection="column">
                      <Text fontSize="14px" ml={2} fontWeight="bold">
                        A pesquisa foi concluída com sucesso. Você acaba de economizar 02 minutos de trabalho!
                      </Text>

                      <Text ml={2}>
                        <span
                          onClick={() => {
                            onClose();
                            fetchTaxCurrentAccountData();
                          }}
                          style={{cursor: 'pointer'}}
                        >
                          <u>Clique aqui</u>
                        </span> para atualizar a página e visualizar os novos registros.
                      </Text>
                    </Flex>

                    <Button
                      margin="-15px -10px 0 0"
                      onClick={onClose}
                      variant="ghost"
                      _hover={{bg: 'none'}}
                    >
                      <CloseIcon w={2.5} h={2.5}/>
                    </Button>
                  </Flex>
                </Box>
              </>
            )
          });
        }
      });
    }
  };

  useEffect(() => {
    if(props.socket || (props.socket && refreshData)) {
      props.socket.off('refreshTaxCurrentAccount');
      activeWebsocket();
    }
    // eslint-disable-next-line
  }, [props.socket, refreshData, filterDataSelected, metaData]);

  const getTaxCurentAccountFilter = (): IFilterListTaxCurrentAccount => {

    const startCreatedAtDate: string|undefined = filterDataSelected ? filterDataSelected[FilterTypeEnum.startCreatedAtFilter][0] : undefined;
    const endCreatedAtDate: string|undefined = filterDataSelected ? filterDataSelected[FilterTypeEnum.endCreatedAtFilter][0] : undefined;

    const companies: string[] = filterDataSelected ? filterDataSelected[FilterTypeEnum.companyFilter] : [];
    const companyGroups: string[] = filterDataSelected ? filterDataSelected[FilterTypeEnum.companyGroupFilter] : [];
    const companyTypes: string[] = filterDataSelected ? filterDataSelected[FilterTypeEnum.companyTypeFilter] : [];
    const companyCodes: string[] = filterDataSelected ? filterDataSelected[FilterTypeEnum.companyCodeFilter] : [];

    const taxCurrentAccountStatuses: string[] = filterDataSelected ? filterDataSelected[FilterTypeEnum.taxCurrentAccountStatusFilter] : [];
    const taxCurrentAccountTypes: string[] = filterDataSelected ? filterDataSelected[FilterTypeEnum.taxCurrentAccountTypeFilter] : [];
    const jurisdictions: string[] = filterDataSelected ? filterDataSelected[FilterTypeEnum.jurisdictionFilter] : [];
    const taxCurrentAccountStates: string[] = filterDataSelected ? filterDataSelected[FilterTypeEnum.taxCurrentAccountStateFilter] : [];
    const taxCurrentAccountCities: string[] = filterDataSelected ? filterDataSelected[FilterTypeEnum.taxCurrentAccountCityFilter] : [];
    const taxCurrentAccountOrigin: string[] = filterDataSelected ? filterDataSelected[FilterTypeEnum.taxCurrentAccountOriginFilter] : [];
    const searchTypes: string[] = filterDataSelected ? filterDataSelected[FilterTypeEnum.searchTypeFilter] : [];

    const filters: IFilterListTaxCurrentAccount = {
      page: metaData?.currentPage ? Number(metaData.currentPage) : 1,
      row_by_page: metaData?.rowByPage ? Number(metaData?.rowByPage) : -1,
      order_field: metaData?.orderField ?? 'queryDate',
      order_direction: metaData?.orderDirection ?? 'desc',
      
      ...(validFilterField(startCreatedAtDate) ? { initial_created_at_date: startCreatedAtDate } : {}),
      ...(validFilterField(endCreatedAtDate) ? { final_created_at_date: endCreatedAtDate } : {}),

      ...(validFilterField(companies) ? { companies: companies.join(',') } : {}),
      ...(validFilterField(companyGroups) ? { company_group: companyGroups.join(',') } : {}),
      ...(validFilterField(companyTypes) ? { company_types: companyTypes.join(',') } : {}),
      ...(validFilterField(companyCodes) ? { company_code: companyCodes.join(',') } : {}),

      ...(validFilterField(taxCurrentAccountStatuses) ? { status: taxCurrentAccountStatuses.join(',') } : {}),
      ...(validFilterField(taxCurrentAccountTypes) ? { slugs: taxCurrentAccountTypes.join(',') } : {}),
      ...(validFilterField(jurisdictions) ? { jurisdiction: jurisdictions.join(',') } : {}),
      ...(validFilterField(taxCurrentAccountStates) ? { id_states: taxCurrentAccountStates.join(',') } : {}),
      ...(validFilterField(taxCurrentAccountCities) ? { id_cities: taxCurrentAccountCities.join(',') } : {}),
      ...(validFilterField(taxCurrentAccountOrigin) ? { origins: taxCurrentAccountOrigin.join(',') } : {}),
      ...(validFilterField(searchTypes) ? { search_types: searchTypes.join(',') } : {}),
    };

    return filters;
  };

  const fetchTaxCurrentAccountData = async () => {
    setLoading(true);
    const { status, response } = await listTaxCurrentAccount(guid_client as string, getTaxCurentAccountFilter());

    if(status && status === 200) {
      setTaxCurrentAccountList({ data: (response as ITaxCurrentAccountList).data, meta: (response as ITaxCurrentAccountList).meta });
    } else {
      toast({
        title: "Ocorreu um erro",
        description: "Ocorreu um erro ao buscar os dados da conta corrente fiscal.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
    setLoading(false);
  };

  const taxCurrentAccountColumns = [
    {
      title: 'Consulta',
      name: 'tax_current_account_name',
      sortable: false,
      align: 'center',
    },
    {
      title: 'Origem - Órgão',
      name: 'origin_name',
      sortable: true,
      align: 'center',
    },
    {
      title: 'Status',
      name: 'status.name',
      orderFieldName: 'slug_status',
      sortable: true,
      align: 'center',
      dynamicTag: {
        function: taxCurrentAccountStatusForTagList,
      },
    },
    {
      title: 'Empresa - cód',
      name: 'company.displayName',
      orderFieldName: 'company.name',
      sortable: true,
      align: 'center',
      hyperlink: (row: ITaxCurrentAccountListData) => {
        setCurrentTaxCurrentAccount(row);
        setOpenHistoryModal(true);
      }
    },
    {
      title: 'Tipo',
      name: 'company.type',
      orderFieldName: 'company.type',
      sortable: true,
      align: 'center',
      tags: entityTags('number'),
    },
    {
      title: 'CNPJ',
      name: 'company.cnpjFormatted',
      orderFieldName: 'company.cnpj',
      sortable: true,
      align: 'center',
      icon: (row: ITaxCurrentAccountListData) => errorMessage(row),
    },
    {
      title: 'Grupo',
      name: 'groups_names',
      orderFieldName: 'group_names',
      sortable: false,
      align: 'center',
    },
    {
      title: 'Data de Consulta',
      name: 'createdAtFormatted',
      orderFieldName: 'queryDate',
      sortable: true,
      align: 'center',
    },
    {
      title: 'Jurisdição',
      name: 'jurisdiction',
      sortable: true,
      align: 'center',
      tags: jurisdictionTags(),
    },
    {
      title: 'Estado',
      name: 'state.initials',
      orderFieldName: 'state.initials',
      sortable: true,
      align: 'center',
      dynamicTag: {
        function: stateRegionForTag,
        field: 'state.initials'
      },
    },
    {
      title: 'Cidade',
      name: 'city.name',
      orderFieldName: 'city_name',
      sortable: true,
      align: 'center',
      tags: [
        { 
          color: '#E2E8F0',
          textColor: '#1A202C'
        },
      ]
    },
  ] as IColumn[];

  const taxCurrentAccountActions = [
    {
      text: 'Visualizar relatório',
      icon: <Image src="/icons/table/eye.svg" width={5} height="auto" />,
      isVisible: (taxCurrentAccount: ITaxCurrentAccountListData): boolean => {return true },
      isDisabled: (taxCurrentAccount: ITaxCurrentAccountListData): boolean => { return !taxCurrentAccount.file },
      action: (taxCurrentAccount: ITaxCurrentAccountListData) => {
        setCurrentFile(taxCurrentAccount.file||'');
        setOpenFileModal(true);
      },
    },
    {
      text: 'Histórico de pesquisa',
      icon: <Image src="/icons/table/clock.svg" width={5} height="auto" />,
      isVisible: (taxCurrentAccount: ITaxCurrentAccountListData): boolean => {return true},
      isDisabled: (taxCurrentAccount: ITaxCurrentAccountListData): boolean => { return false },
      action: (taxCurrentAccount: ITaxCurrentAccountListData) => {
        setCurrentTaxCurrentAccount(taxCurrentAccount);
        setOpenHistoryModal(true);
      },
    },
    {
      text: 'Baixar débitos',
      icon: <Image src="/icons/table/download.svg" width={5} height="auto" />,
      isVisible: (taxCurrentAccount: ITaxCurrentAccountListData): boolean => {return true},
      isDisabled: (taxCurrentAccount: ITaxCurrentAccountListData): boolean => { return !taxCurrentAccount.fileXlsDebts || taxCurrentAccount.isManual === true },
      action: async (taxCurrentAccount: ITaxCurrentAccountListData) => {
        if(!taxCurrentAccount.fileXlsDebts) {
          return;
        }

        const extension = taxCurrentAccount.fileXlsDebts?.split('.').pop();

        const fileName = `Taxly - ${taxCurrentAccount.company.name} - ${moment.utc().format('DD_MM_YYYY')}.${extension}`;

        await downloadFile(taxCurrentAccount.fileXlsDebts, fileName);
      },
    },
    {
      text: 'Atualizar consulta',
      icon: <Image src="/icons/table/refresh.svg" width={5} height="auto" />,
      isVisible: (taxCurrentAccount: ITaxCurrentAccountListData): boolean => {return true},
      isDisabled: (taxCurrentAccount: ITaxCurrentAccountListData): boolean => { return !checkCollaboratorPermission(validate as IResponse,guid_client as string,"taxCurrentAccount.write") },
      action: (taxCurrentAccount: ITaxCurrentAccountListData) => {
        setCurrentTaxCurrentAccount(taxCurrentAccount);
        setOpenSearchTaxCurrentAccountModal(true);
      },
    },
  ] as IAction[];

  return (<>
    <Flex direction="column" w="100%" p="0" flexGrow="1" flexShrink="1" pr="30px" mt="10px">
      <Flex flexDirection="row" justifyContent="space-between" borderBottom="1px" borderBottomColor="gray.300" pb={4}>
        <Text fontSize="18px" mt={8} fontFamily="Poppins-Medium">
          {`Conta Corrente Fiscal - Visão Geral`}
        </Text>
        <Flex gap={2}>
          <TaxCurrentAccountMultiDownload getTaxCurentAccountFilter={getTaxCurentAccountFilter} />
          <TaxCurrentAccountAddNewManual flushHook={setRefreshData} />
        </Flex>
      </Flex>

      <Flex mt={4}>
        <Filters
          filters={taxCurrentAccountFilter}
          selectedValuesHook={setFilterDataSelected}
        /> 
      </Flex>

      <Flex mt={12} direction="column" position="relative">
        <Datatables
          name="Relatórios"
          tableId={'tax-current-account'}
          data={taxCurrentAccountList}
          columns={taxCurrentAccountColumns}
          actions={taxCurrentAccountActions}
          metaDataHook={setMetaData}
          isLoading={isLoading}
          guid_client={guid_client as string}
        />
      </Flex>
    </Flex>

    <ViewPdfModal 
      title="Visualizar Arquivo" 
      file={currentFile} 
      openModal={openFileModal}
      openModalHook={setOpenFileModal}
    />

    {currentTaxCurrentAccount ? 
    <>
      <TaxCurrentAccountHistory
        taxCurrentAccount={currentTaxCurrentAccount}
        openModal={openHistoryModal}
        openModalHook={setOpenHistoryModal}
      />

      <TaxCurrentAccountSearchModal
        taxCurrentAccount={currentTaxCurrentAccount}
        openModal={openSearchTaxCurrentAccountModal}
        openModalHook={setOpenSearchTaxCurrentAccountModal}
        flushHook={setRefreshData}
      />
    </>
    : null }
  </>);
}
