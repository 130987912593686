import { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Flex,
  Image,
  Link,
  Select,
  Switch,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import {
  ITableColumnsConfig,
  IUpdateTableColumnsConfigParams,
} from "../../../_services/interface/table-columns-config.interface";
import {
  createTableColumnsConfig,
  updateTableColumnsConfig,
} from "../../../_services/table-columns-config.service";
import ColumnsDragAndDrop, { IItemDND } from "./ColumnsDragAndDrop";
import { EditableConfigName } from "./EditableConfigName";
import { AddIcon } from "@chakra-ui/icons";

export interface IUpdateColumnsConfig {
  columns?: IItemDND[];
  configName?: string;
  isDefault?: boolean;
  isActive?: boolean;
}

interface IPropsTableColumnsConfig {
  guid_client?: string;
  tableId: string;
  columnsDefault: string[];
  tableColumnsConfigs: ITableColumnsConfig[];
  setTableColumnsConfigs: React.Dispatch<
    React.SetStateAction<ITableColumnsConfig[]>
  >;
  setColumnsConfigured: React.Dispatch<
    React.SetStateAction<string[] | undefined>
  >;
  fetchConfigs: () => Promise<void>;
  width?: string;
  left?: string;
  disableDragAndDrop?: boolean;
  type?: 'collaborator'|'client';
  nameItens?: string;
}

export const TableColumnsConfig = (props: IPropsTableColumnsConfig) => {
  const [isDragVisible, setIsDragVisible] = useState(false);
  const [isColumnsLoaded, setIsColumnsLoaded] = useState(false);
  const [shouldUpdateColumns, setShouldUpdateColumns] = useState(false);
  const [selectedConfig, setSelectedConfig] = useState<
    ITableColumnsConfig | undefined
  >(
    props.tableColumnsConfigs.find((config) => config.isDefault) ||
      props.tableColumnsConfigs[0]
  );
  const [columns, setColumns] = useState<IItemDND[]>([]);
  const [refreshColumns, setRefreshColumns] = useState(false);
  const configRef = useRef<HTMLDivElement | null>(null);

  const resetConfig = () => {
    setSelectedConfig(undefined);
    setIsColumnsLoaded(false);
    setRefreshColumns(true);
    setColumns([]);
  };

  useEffect(() => {
    resetConfig();
  }, [props.tableId]);

  useEffect(() => {
    if (
      props.tableColumnsConfigs.length > 0 &&
      props.tableColumnsConfigs.some((tcc) => tcc.table_id === props.tableId) &&
      !selectedConfig
    ) {
      setIsColumnsLoaded(false);
      const defaultConfig = props.tableColumnsConfigs.find(
        (config) => config.isDefault
      );
      setSelectedConfig(defaultConfig || props.tableColumnsConfigs[0]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.tableColumnsConfigs, props.columnsDefault]);

  useEffect(() => {
    if (selectedConfig && !isColumnsLoaded) {
      const orderedColumns = selectedConfig.columns.map((column) => ({
        id: column,
        text: column,
        checked: true,
      }));

      const remainingColumns = props.columnsDefault
        .filter((column) => !selectedConfig.columns.includes(column))
        .map((column) => ({
          id: column,
          text: column,
          checked: false,
        }));

      setColumns([...orderedColumns, ...remainingColumns]);
      setIsColumnsLoaded(true);
      setRefreshColumns(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedConfig]);

  useEffect(() => {
    props.setColumnsConfigured(
      columns.filter((col) => col.checked).map((col) => col.id)
    );
    if (shouldUpdateColumns) {
      updateColumnConfig({ columns });
      setShouldUpdateColumns(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columns]);

  const updateColumnConfig = async (updateConfig: IUpdateColumnsConfig) => {
    if (
      selectedConfig &&
      props.guid_client &&
      props.tableId &&
      ((updateConfig.columns && updateConfig.columns?.length > 0) ||
        updateConfig.configName ||
        typeof updateConfig.isDefault === "boolean" ||
        typeof updateConfig.isActive === "boolean")
    ) {
      const newColumns = updateConfig.columns
        ? updateConfig.columns.filter((col) => col.checked).map((col) => col.id)
        : null;
      const updatedConfig = {
        guid_table_config: selectedConfig.guid_table_columns_config,
        tableId: props.tableId,
        ...(newColumns && newColumns.length > 0 && { columns: newColumns }),
        ...(updateConfig.configName && { configName: updateConfig.configName }),
        ...(typeof updateConfig.isDefault === "boolean" && {
          isDefault: updateConfig.isDefault,
        }),
        ...(typeof updateConfig.isActive === "boolean" && { isActive: false }),
      } as IUpdateTableColumnsConfigParams;

      const { status, response } = await updateTableColumnsConfig(
        props.guid_client,
        updatedConfig,
        (props.type || 'collaborator')
      );

      if (status && status === 200) {
        const configLocalStorage = localStorage.getItem(
          `tableColumnsConfig-${props.tableId}`
        );

        if (configLocalStorage) {
          const configParsed = JSON.parse(
            configLocalStorage
          ) as ITableColumnsConfig[];
          const updatedConfigs = configParsed.map((config) => {
            if (
              config.guid_table_columns_config ===
              (response as ITableColumnsConfig).guid_table_columns_config
            ) {
              return response;
            }
            return config;
          });
          props.setTableColumnsConfigs(updatedConfigs as ITableColumnsConfig[]);
        }
      } else if (status && status === 404) {
        localStorage.removeItem(`tableColumnsConfig-${props.tableId}`);
        resetConfig();
        props.fetchConfigs();
      }
    }
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      configRef.current &&
      !configRef.current.contains(event.target as Node)
    ) {
      setIsDragVisible(false);
    }
  };

  const handleConfigChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedGuid = event.target.value;
    const newSelectedConfig = props.tableColumnsConfigs.find(
      (config) => config.guid_table_columns_config === selectedGuid
    );
    setIsColumnsLoaded(false);
    setSelectedConfig(newSelectedConfig);
  };

  const handleCreateConfig = async () => {
    let configName = `Nova Configuração`;
    let i = 1;

    const isConfigNameTaken = (name: string) => {
      return props.tableColumnsConfigs.some((config) => config.config_name === name);
    }

    while (isConfigNameTaken(configName)) {
      configName = `Nova Configuração ${i}`;
      i++;
    }

    if (props.guid_client && props.tableId) {
      const { status, response } = await createTableColumnsConfig(
        props.guid_client,
        {
          tableId: props.tableId,
          configName: configName,
          columns: props.columnsDefault,
          isDefault: false,
        },
        (props.type || 'collaborator')
      );

      if (status && status === 200) {
        const updatedConfigs = [
          ...props.tableColumnsConfigs,
          response as ITableColumnsConfig,
        ];
        props.setTableColumnsConfigs(updatedConfigs);
        setIsColumnsLoaded(false);
        setSelectedConfig(response as ITableColumnsConfig);
      }
    }
  };

  const handleDeleteConfig = () => {
    // Função para excluir a configuração selecionada
    if (selectedConfig && !selectedConfig.isDefault) {
      const updatedConfigs = props.tableColumnsConfigs.filter(
        (config) =>
          config.guid_table_columns_config !==
          selectedConfig.guid_table_columns_config
      );
      updateColumnConfig({ isActive: false });
      setSelectedConfig(undefined);
      props.setTableColumnsConfigs(updatedConfigs);
    }
  };

  const handleUpdateDefaultConfig = () => {
    if (selectedConfig && !selectedConfig.isDefault) {
      const updatedConfigs = props.tableColumnsConfigs.map((config) => {
        if (
          config.guid_table_columns_config ===
          selectedConfig.guid_table_columns_config
        ) {
          return {
            ...config,
            isDefault: true,
          };
        } else if (config.isDefault) {
          return {
            ...config,
            isDefault: false,
          };
        }
        return config;
      });
      updateColumnConfig({ isDefault: true });
      setSelectedConfig({ ...selectedConfig, isDefault: true });
      props.setTableColumnsConfigs(updatedConfigs);
    }
  };

  useEffect(() => {
    if (isDragVisible) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isDragVisible]);

  return (
    <Flex position={"relative"} ref={configRef}>
      <Link
        display={"flex"}
        color={"#4B4EFF"}
        fontSize={"12px"}
        marginRight={"10px"}
        onClick={() => setIsDragVisible(!isDragVisible)} // Alterna a visibilidade
        cursor="pointer"
      >
        <Image
          width={"16px"}
          src="/icons/engrenagem-azul.svg"
          alt="engrenagem"
          marginRight={"1px"}
        />
        <Text paddingTop={"3px"}>Personalizar</Text>
      </Link>

      <Box
        position={"absolute"}
        width={props.width || "300px"}
        left={props.left || 0}
        mx="auto"
        mt={"22px"}
        border="1px solid #ccc"
        borderRadius="md"
        zIndex={11}
        bg="white"
        fontFamily={"Poppins-Medium"}
        fontSize={"14px"}
        fontWeight={"500"}
        display={isDragVisible ? "block" : "none"}
      >
        <Flex p={"8px"} pb={"10px"} direction={"column"}>
          <Text mb={"8px"}>Configurações</Text>
          <Flex mb={4} alignItems="center">
            <Select
              value={selectedConfig?.guid_table_columns_config}
              onChange={handleConfigChange}
              mr={2}
              fontSize={"12px"}
            >
              {props.tableColumnsConfigs.map((config) => (
                <option
                  key={config.guid_table_columns_config}
                  value={config.guid_table_columns_config}
                >
                  {config.config_name}
                </option>
              ))}
            </Select>

            <Flex className="icon-bg-blue" mr={1}>
              <Button onClick={handleCreateConfig} background={'#4b4dff'} m={0} p={0} width="30px" height="30px" minWidth="30px" minHeight="30px" >
                <Flex alignItems="center" justifyContent="center" display="flex" width="30px" height="30px" minWidth="30px" minHeight="30px" >
                  <AddIcon color={'white'} />
                </Flex>
              </Button>
            </Flex>
            <Tooltip
              label={
                "Você não pode excluir a configuração principal. Selecione outra como principal para excluir essa."
              }
              isDisabled={!selectedConfig?.isDefault}
              placement="top"
            >
              <span>
                <Flex className="icon-bg-blue">
                  <Button onClick={handleDeleteConfig} isDisabled={selectedConfig?.isDefault} background={'#4b4dff'} m={0} p={0} width="30px" height="30px" minWidth="30px" minHeight="30px" >
                    <Flex alignItems="center" justifyContent="center" display="flex" width="30px" height="30px" minWidth="30px" minHeight="30px" >
                      <Image height="20px" width="20px" src="/icons/trash.svg" resize="none" _hover={{ cursor: selectedConfig?.isDefault ? "block" : "pointer" }}/>
                    </Flex>
                  </Button>
                </Flex>
              </span>
            </Tooltip>
          </Flex>

          <EditableConfigName
            selectedConfig={selectedConfig}
            setSelectedConfig={setSelectedConfig}
            updateColumnConfig={updateColumnConfig}
          ></EditableConfigName>

          <Flex p={2} justifyContent={"space-between"} alignItems={"center"}>
            <Box>Definir como principal</Box>
            <Tooltip
              label={"Essa já é a configuração principal"}
              isDisabled={!selectedConfig?.isDefault}
              placement="top"
            >
              <span>
                <Switch
                  isChecked={selectedConfig?.isDefault}
                  isDisabled={selectedConfig?.isDefault}
                  onChange={handleUpdateDefaultConfig}
                  mt={"8px"}
                  sx={{
                    "& .chakra-switch__track": {
                      backgroundColor: selectedConfig?.isDefault
                        ? "#4B4EFF"
                        : "#e2e8f0",
                    },
                  }}
                />
              </span>
            </Tooltip>
          </Flex>
        </Flex>
        <Flex
          direction={"column"}
          padding={"8px"}
          borderTop={"1px solid #ccc"}
          pt={"10px"}
        >
          <ColumnsDragAndDrop
            items={columns}
            setItems={setColumns}
            refreshItems={refreshColumns}
            setRefreshItems={setRefreshColumns}
            setShouldUpdateItems={setShouldUpdateColumns}
            disableDragAndDrop={props.disableDragAndDrop}
            nameItens={props.nameItens}
          />
        </Flex>
      </Box>
    </Flex>
  );
};
