import { CircularProgress, CircularProgressLabel, Flex, Text, useDisclosure } from "@chakra-ui/react";
import { ICNDListData, IFilterListCertificate } from "../../../../_services/interface/cnd.interface"
import { ICardList } from "../../TaxKanban/interfaces/ICardList";
import { IWidgetIdent } from "../interfaces/IWidget";
import { calcPercent } from "../util/util";
import { WidgetBase } from "./WidgetBase"
import moment from 'moment';
import { IClientDataResponse } from "../../../../_services/interface/client.interface";
import { ICertificate } from "../../../../_services/interface/certificate.interface";
import { getCertificatesPropertyValuesRecursively } from "../../Certificate/Util/CertificateUtils";
import ReactPlaceholder from "react-placeholder/lib";
import { RectShape, RoundShape } from "react-placeholder/lib/placeholders";
import { useState } from "react";
import { ViewCertificatesModal } from "../../CNDManager/modals/ViewCertificatesModal";
moment.updateLocale('pt', {
  months: [
    "Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho",
    "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"
  ]
});

interface ICndComplianceWidget extends IWidgetIdent {
  data?: ICNDListData[] | ICardList[];
  isLoading?: boolean;
  currentClient?: IClientDataResponse
  certificates?: ICertificate[];
  startDateFilter?: string;
  endDateFilter?: string;
  filter?: IFilterListCertificate;
}

export const CndComplianceWidget = (props: ICndComplianceWidget) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [ jurisdiction, setJurisdiction ] = useState<string|null>(null);
  const [ dataTypeToModal, setDataTypeToModal ] = useState<'general'|null>(null);
  const [ title, setTitle ] = useState<string>('');

  const cndData = props.data ? props.data as ICNDListData[] : [];
  
  const products = props.currentClient?.products ? props.currentClient.products : [];

  const slugsAvailable: string[] = [];
  props.certificates?.forEach((certificate) => {
    let hasProduct = products[certificate.slug as string];
    if(!hasProduct && certificate.children) {
      hasProduct = certificate.children.some((child) => products[child.slug as string]);
    }

    if (certificate.slug && hasProduct) {
      const slugs = getCertificatesPropertyValuesRecursively([certificate], 'slug' as keyof ICertificate);
      slugs[0]?.forEach((slug: string) => {
        slugsAvailable.push(slug);
      });
    }
  }); 

  const closeModal = () => {
    onClose();
    setDataTypeToModal(null);
    setTitle('');
    setJurisdiction(null);
  }

  const getFilter = () => {
    if (jurisdiction) {
      const filter: IFilterListCertificate = {
        page: props.filter ? props.filter.page : 1,
        row_by_page: props.filter ? props.filter.row_by_page : -1,
        ...props.filter,
        jurisdiction: jurisdiction,
      };

      return filter;
    }

    return props.filter;
  };

  if (props.id && props.index !== undefined && props.currentClient && props.certificates) {
    const issuedData = cndData.filter((x) => ['not-pendency', 'negative-positive'].includes(x.slug_status) && x && slugsAvailable.includes(x.slug as string)); 
    const availableData = cndData.filter((x) => slugsAvailable.includes(x.slug as string)); 

    const availableDataEstate = availableData.filter((x) => x.jurisdiction === 'state');
    const availableDataFederal = availableData.filter((x) => x.jurisdiction === 'federal');
    const availableDataMunicipal = availableData.filter((x) => x.jurisdiction === 'municipal');

    const stateCalcTotal     = availableDataEstate.length;
    const federalCalcTotal   = availableDataFederal.length;
    const municipalCalcTotal = availableDataMunicipal.length;

    const stateCalc     = issuedData.filter((x) => x.jurisdiction === 'state').length;
    const federalCalc   = issuedData.filter((x) => x.jurisdiction === 'federal').length;
    const municipalCalc = issuedData.filter((x) => x.jurisdiction === 'municipal').length;

    const openFederal = () => {
      setDataTypeToModal('general');
      setTitle('Certidões Federais');
      setJurisdiction('federal');
      onOpen();
    }

    const openState = () => {
      setDataTypeToModal('general');
      setTitle('Certidões Estaduais');
      setJurisdiction('state');
      onOpen();
    }

    const openMunicipal = () => {
      setDataTypeToModal('general');
      setTitle('Certidões Municipais');
      setJurisdiction('municipal');
      onOpen();
    }
    
    return (
      <WidgetBase title={`Compliance por jurisdição`} id={props.id} index={props.index}>
        <Flex flexDirection="column" gap={4} width="100%" alignItems="center" justifyContent="center">
          <Flex flexDirection="row" gap={4} width="100%" alignItems="center" justifyContent="center"> 

            <Flex cursor="pointer" flexDirection="column" onClick={openFederal} justifyContent="center" gap={4}>
              <ReactPlaceholder showLoadingAnimation type='text' ready={!props.isLoading} rows={1}>
                <Text textAlign="center">Federal</Text>
              </ReactPlaceholder>
              
              {
                props.isLoading ? (
                  <RoundShape color="#e0e0e0" style={{width: 130, height: 130}}/>
                ) : (
                  <CircularProgress value={calcPercent(federalCalc,federalCalcTotal)} color="#4b4eff" size='150px' thickness='10px'>
                    <CircularProgressLabel fontSize={14} fontWeight="bold">
                      {calcPercent(federalCalc,federalCalcTotal)}%<br/>Emitido
                    </CircularProgressLabel>
                  </CircularProgress>
                )
              }
            </Flex>
          
            <Flex flexDirection="column" cursor="pointer" onClick={openState} justifyContent="center" gap={4}>
              <ReactPlaceholder showLoadingAnimation type='text' ready={!props.isLoading} rows={1}>
                <Text textAlign="center">Estadual</Text>
              </ReactPlaceholder>

              {
                props.isLoading ? (
                  <RoundShape color="#e0e0e0" style={{width: 130, height: 130}}/>
                ) : (
                  <CircularProgress value={calcPercent(stateCalc,stateCalcTotal)} color="#4b4eff" size='150px' thickness='10px'>
                    <CircularProgressLabel fontSize={14} fontWeight="bold">
                      {calcPercent(stateCalc,stateCalcTotal)}%<br/>Emitido
                    </CircularProgressLabel>
                  </CircularProgress>
                )
              }
            </Flex>
            
            <Flex flexDirection="column" cursor="pointer" onClick={openMunicipal} justifyContent="center" gap={4}>
              <ReactPlaceholder showLoadingAnimation type='text' ready={!props.isLoading} rows={1}>
                <Text textAlign="center">Municipal</Text>
              </ReactPlaceholder>

              {
                props.isLoading ? (
                  <RoundShape color="#e0e0e0" style={{width: 130, height: 130}}/>
                ) : (
                  <CircularProgress value={calcPercent(municipalCalc,municipalCalcTotal)} color="#4b4eff" size='150px' thickness='10px'>
                    <CircularProgressLabel fontSize={14} fontWeight="bold">
                      {calcPercent(municipalCalc,municipalCalcTotal)}%<br/>Emitido
                    </CircularProgressLabel>
                  </CircularProgress> 
                )
              }
            </Flex>
          </Flex>
        </Flex>

        {props.certificates && props.currentClient ? (
          <>
            <ViewCertificatesModal
              guid_client={props.currentClient.guid_client}
              title={title}
              isOpen={isOpen}
              onClose={closeModal}
              filter={getFilter()}
              type={dataTypeToModal}
            />
          </>
        ) : null}
        
      </WidgetBase>
    )
  } else {
    return null;
  }
}