import { Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Text, useDisclosure, useToast } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FormButton } from "../../../../components/login/button.component";
import { getCertificate, patchActiveCertificate } from "../../../../_services/digitalCertificate.service";
import { IDigitalCertificate } from "../../../../_services/interface/digitalCertificate.interface";
import { Validation2fa } from "../../../../modal/Validation2fa";

interface IActiveDigitalCertificateModalProps {
  disclosureProps: ReturnType<typeof useDisclosure>;
  digitalCertificate: IDigitalCertificate;
  guid_client: string;
  setRefreshTable: (value: boolean) => void;
}

export const ActiveDigitalCertificateModal = (props: IActiveDigitalCertificateModalProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [ isButtonDisabled, setButtonDisabled ] = useState<boolean>(false);
  const [ isLoading, setIsLoading ] = useState<boolean>(false);
  const [digitalCertificate, setDigitalCertificate] = useState<IDigitalCertificate|null>(null);
  const toast = useToast();

  const yesOption = async () => {
    setButtonDisabled(true);

    if(!digitalCertificate?.companyOwner?.guid_company) {
      return;
    }
    
    const { status, response } = await patchActiveCertificate(props.guid_client, digitalCertificate.guid_digital_certificate, digitalCertificate.is_active ? 0 : 1);

    if (status === 200) {
      toast({
        title: 'Certificado digital atualizado com sucesso',
        description: `O certificado digital foi atualizado com sucesso`,
        status: 'success',
        isClosable: true,
        duration: 5000,
      });
      onClose();
      props.setRefreshTable(true);
    } else {
      toast({
        title: 'Falha ao atualizar o certificado digital',
        description: `Algum erro ocorreu ao tentar atualizar o certificado digital. Tente novamente mais tarde`,
        status: 'error',
        isClosable: true,
        duration: 5000,
      });
      onClose();
    }
    setButtonDisabled(false);
  };

  const config = async () => {
    setIsLoading(true);
    const { status, response } = await getCertificate(props.guid_client, props.digitalCertificate.guid_digital_certificate);
    if (status === 200 && typeof response === 'object' && 'guid_digital_certificate' in response) {
      setDigitalCertificate(response as IDigitalCertificate);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    if (isOpen) {
      config();
    }
  }, [isOpen]);

  return (
    <>
      <Modal closeOnOverlayClick={false} isCentered={true} isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay bg='blackAlpha.300' backdropFilter='blur(10px)' alignItems="center" />
        <ModalContent flexGrow={1} flexShrink={1} borderLeft="12px solid #0263FF" borderRadius={8}>
          <ModalHeader fontFamily="Poppins-SemiBold">{digitalCertificate?.is_active ? 'Desativar' : 'Ativar'} certificado digital</ModalHeader>
          <ModalCloseButton />
          <ModalBody fontFamily="Poppins-medium" fontSize={12}>
            <Flex justifyContent="center" flexDirection="column" gap={5}>
              <Text fontSize={14}>Tem certeza que deseja {digitalCertificate?.is_active ? 'desativar' : 'ativar'} o certificado digital da empresa <strong>{props.digitalCertificate?.companyOwner.name}</strong>?</Text>
              <Text>Atualmente existem {props.digitalCertificate?.companies ? props.digitalCertificate.companies.length : 1} empresas vinculadas a este certificado</Text>
              <Flex gap={2} flexGrow={1} justifyContent="end" alignItems="center">
                <FormButton 
                  onClick={onClose}
                  disabled={isButtonDisabled}
                  width="50%"
                  color="white"
                  textColor="#4B4EFF"
                  border="1px solid #4B4EFF"
                >
                  Cancelar
                </FormButton>

                <FormButton 
                  onClick={yesOption}
                  isLoading={isLoading}
                  disabled={isButtonDisabled || isLoading}
                  width="50%"
                  {...(
                    digitalCertificate?.is_active
                      ? {
                          _hover: { bgColor: "#B91C1C", cursor: "pointer", color: "white" },
                          color: "#EF4444",
                        }
                      : {}
                  )}
                >
                  {digitalCertificate?.is_active ? 'Desativar' : 'Ativar'}
                </FormButton>
              </Flex>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>

      <Validation2fa
        callback={onOpen}
        disclosureProps={props.disclosureProps}
      />
    </>
  );
}